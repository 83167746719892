import { Component } from 'react';
import { connect } from 'react-redux';
import {
  getStories,
  getStoryUpdates,
  preventStoryUpdate,
  setFeedReqData,
  getStoriesByPackage,
  resetStoriesResetHandle,
} from '../../actions/feeds.action';
import { UpdateInitialColumnLoadCounter } from '../../actions/settings.action';
import { APPPATH, EventsUpdateHandler } from '../../modules/helper.module';
import * as moment from 'moment';

class StoryContextHandle extends Component {
  constructor(props) {
    super();
    this.state = {
      StoriesIndex: [],
      feedReqData: {
        InitialFeedRequest: '',
        LastFeedRequest: '',
        LastFeedRequestEnd: '',
        InitialFeedResponse: '',
        LastFeedResponse: '',
        RequestState: false,
      },
      resetHandle: false,
      useCase: '',
      FirstUpdate: true,
      thisInstance: this,
      utcOffset: 0,
      mouseMoveOverStory: false,
      pauseStoryUpdate: false,
      allPackageCalled: false,
      cond_NACE: false,
      cond_SECTYPES: false,
      cond_SECTYPESFILT: false,
      cond_SECFILT: false,
      cond_EVNTFILT: false,
      postponeupdate: props.postponeupdate
    };

    // this.packageRequestStatus = false;
    this.storiesUpdateHandle = '';
    this.storiesUpdateInterval = '';
    this.updateFail = 0;
    // console.log('Initial', props.securitiesRawCollection)
    // console.log('Language', props.lang)
  }

  componentDidMount() {
    if (this.props.render === 'iframe') {
      // console.log(this.props.securitiesFilter, this.props.eventFilter)
      this.initGetStoriesByPackage();
    }

  }

  initGetStoriesByPackage = (packageDump = []) => {
    // console.log(packageDump)
    // this.packageRequestStatus = true;
    if (this.props.render !== 'iframe') {
      let showGray = this.props.Show_Gray;
      let CallingPackage = !showGray ? this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? 'cryptocurrencyorange' : 'companyorange' : false;
      const reqData = {
        ...this.state.feedReqData,
        RequestState: true,
        InitialFeedRequest:
          this.props.userData.userPackage.toLowerCase() === 'bronze'
            ? moment().utcOffset(this.state.utcOffset).subtract(15, 'minutes').format()
            : moment().utcOffset(this.state.utcOffset).format(),
        LastFeedRequest:
          this.props.userData.userPackage.toLowerCase() === 'bronze'
            ? moment().utcOffset(this.state.utcOffset).subtract(15, 'minutes').format()
            : moment().utcOffset(this.state.utcOffset).format(),
      };
      this.storiesUpdateHandle = new EventsUpdateHandler(reqData);
      this.props.setFeedReqData(reqData);
      this.setState(
        {
          ...this.state,
          feedReqData: reqData,
          cond_NACE: false,
          cond_SECTYPES: false,
          cond_SECTYPESFILT: false,
          cond_SECFILT: false,
          cond_EVNTFILT: false,
        },
        () => {
          if (this.state.resetHandle) {
            this.props.resetStoriesResetHandle();
          }
          const Packages = packageDump
            .map((itm) => !itm.children.length && itm.Name.toLowerCase() !== 'none' && itm.Name)
            .filter((itm) => itm);
          if (Packages.length) {
            Packages.map((pkg, cnt) => {
              // console.log(CallingPackage, pkg)

              if (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) {
                pkg = 'Company';
              }
              if (this.props.userpreference_NACE) {
                this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE);
                // this.props.getStoriesByPackage(CallingPackage || pkg, !this.props.userpreference_NACE)
              } else {
                if (this.props.userpreference_NACE === '') {
                  this.props.getStoriesByPackage(CallingPackage || pkg, /*this.props.userpreference_NACE*/ false);
                } else {
                  this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE);
                }
              }

              return null;
            });
          }
        }
      );
    } else {
      this.props.getStoriesByPackage();
    }

  };

  initGetStories = () => {
    const reqData = {
      ...this.state.feedReqData,
      RequestState: true,
      InitialFeedRequest: moment().utcOffset(this.state.utcOffset).format(),
      LastFeedRequest: moment().utcOffset(this.state.utcOffset).format(),
    };
    this.storiesUpdateHandle = new EventsUpdateHandler(reqData);
    this.props.setFeedReqData(reqData);
    this.setState(
      {
        ...this.state,
        feedReqData: reqData,
      },
      () => {
        // this.props.setFeedReqData(this.state.feedReqData),
        // this.props.getStories(false, this.props.userData.username, this.props.settings.apiBaseUrl, this.props.settings.headers, true, this.props.userpreference_NACE);
        if (this.props.userpreference_NACE) {
          // call for analysed stories
          this.props.getStories(false, true, false, true);
          //call for with curated stories
          this.props.getStories(false, true, this.props.userpreference_NACE);
        } else {
          //call for both
          this.props.getStories(false, true, this.props.userpreference_NACE, 'mix');
        }
      }
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(prevState.thisInstance.props.postponeupdate, nextProps.postponeupdate)
    if (prevState.thisInstance.props.postponeupdate !== nextProps.postponeupdate) {
      return { postponeupdate: nextProps.postponeupdate };
    }
    if (
      prevState.thisInstance.props.userpreference_NACE === '' &&
      prevState.thisInstance.props.userpreference_NACE !== nextProps.userpreference_NACE
    ) {
      // prevState.thisInstance.initGetStories()
      // console.log(nextProps.userpreference_NACE)
      // console.log(nextProps.securityFilter, nextProps.securitiesFilter)

      return { cond_NACE: nextProps.userpreference_NACE };
    }

    if (
      !prevState.thisInstance.props.securitiesRawCollection.length &&
      prevState.thisInstance.props.securitiesRawCollection.length !== nextProps.securitiesRawCollection.length
    ) {
      // console.log(nextProps.securitiesRawCollection)
      // prevState.thisInstance.initGetStoriesByPackage(nextProps.securitiesRawCollection)
      return { cond_SECTYPES: true };
    }

    if (
      !prevState.thisInstance.props.securityFilter.length &&
      prevState.thisInstance.props.securityFilter.length !== nextProps.securityFilter.length
    ) {
      // console.log(nextProps.securityFilter)
      // prevState.thisInstance.initGetStoriesByPackage(nextProps.securitiesRawCollection)
      return { cond_SECTYPESFILT: true };
    }

    if (
      !prevState.thisInstance.props.securitiesFilter.length &&
      prevState.thisInstance.props.securitiesFilter.length !== nextProps.securitiesFilter.length
    ) {
      // console.log(nextProps.securitiesFilter)
      // prevState.thisInstance.initGetStoriesByPackage(nextProps.securitiesRawCollection)
      return { cond_SECFILT: true };
    }
    if (
      !prevState.thisInstance.props.eventFilter.length &&
      prevState.thisInstance.props.eventFilter.length !== nextProps.eventFilter.length
    ) {
      // console.log(nextProps.eventFilter)
      return { cond_EVNTFILT: true };
    }

    if (prevState.thisInstance.props.feedResetHandle !== nextProps.feedResetHandle) {
      // console.log(nextProps.feedResetHandle)
      return { resetHandle: nextProps.feedResetHandle };
    }

    if (prevState.thisInstance.props.StoriesIndex !== nextProps.StoriesIndex) {
      if (prevState.thisInstance.storiesUpdateHandle) {
        // console.log('here in get derived state')
        // if(!prevState.thisInstance.packageRequestStatus) {
        let storyReqData = prevState.thisInstance.storiesUpdateHandle.StoryReqData(
          prevState.thisInstance.props,
          nextProps
        );
        return {
          StoriesIndex: nextProps.StoriesIndex,
          feedReqData: storyReqData.reqData,
          useCase: storyReqData.useCase,
        };
        // }
      }
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("component did update")
    // console.log(prevProps.postponeupdate, this.props.postponeupdate)
    if (prevProps.StoriesIndex !== this.props.StoriesIndex && !this.state.resetHandle && !this.state.postponeupdate) {
      // console.log("component did update = update sequence")
      this.updateFeedRequestData(this.state.useCase);

    }
    if (prevState.postponeupdate !== this.state.postponeupdate && !this.state.postponeupdate) {
      this.updateFeedRequestData(this.state.useCase);
    } else if (prevState.postponeupdate !== this.state.postponeupdate && this.state.postponeupdate) {
      clearTimeout(this.storiesUpdateInterval)
    }
    // console.log(this.props.securitiesRawCollection, prevProps.securitiesRawCollection)

    // if((this.state.cond_SECTYPES && this.state.cond_SECTYPESFILT && this.state.cond_SECFILT && this.state.cond_EVNTFILT) || this.state.resetHandle) {
    // if(this.state.cond_NACE && this.state.cond_SECTYPES && this.state.cond_SECTYPESFILT && this.state.cond_SECFILT) {
    // if (
    //   (this.props.securitiesRawCollection.length !== prevProps.securitiesRawCollection.length &&
    //     prevProps.securitiesRawCollection.length === 0) ||
    //   this.state.resetHandle
    // ) {
    //   // console.log("component did update = initiate get stories squence")
    //   this.initGetStoriesByPackage(this.props.securitiesRawCollection);
    // }
    if (this.props.render !== 'iframe') {
      if (this.props.securitiesRawCollection.length !== prevProps.securitiesRawCollection.length && prevProps.securitiesRawCollection.length === 0 && this.props.securities.length) {
        this.initGetStoriesByPackage(this.props.securitiesRawCollection);
      }
      else if (this.props.securities.length !== prevProps.securities.length && prevProps.securities.length === 0 && this.props.securitiesRawCollection.length) {
        this.initGetStoriesByPackage(this.props.securitiesRawCollection);
      }
    } else {
      // recalling events in smart inbox iframe based on feedResetHandle from feeds store
      if (prevProps.StoriesIndex !== this.props.StoriesIndex && this.state.resetHandle) {
        // console.log('call stories')
        this.initGetStoriesByPackage();
      }
      // if((this.props.securitiesFilter.length !== prevProps.securitiesFilter.length && prevProps.securitiesFilter.length === 0 && this.props.securitiesFilter.length && this.props.eventFilter.length)) {
      //   this.initGetStoriesByPackage();
      //   console.log(this.props.securitiesFilter, this.props.eventFilter)
      //   // console.log(this.props.eventFilter, prevProps.eventFilter)
      // }
      // if(this.props.securitiesFilter.length && this.props.eventFilter.length) {
      //   this.initGetStoriesByPackage();
      // }
    }

  }

  updateFeedRequestData = (useCase) => {
    this.props.setFeedReqData(this.state.feedReqData);
    let StoriesIndex = this.props.StoriesIndex.map((index) => {
      return index.FeedId;
    });
    clearTimeout(this.storiesUpdateInterval);
    let interval = 60000;
    let showGray = this.props.Show_Gray;
    let CallingPackage = !showGray ? this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? 'cryptocurrencyorange' : 'companyorange' : false;
    // console.log(useCase)
    switch (useCase) {
      case 1:
        // this.props.UpdateInitialColumnLoadCounter()
        clearTimeout(this.storiesUpdateInterval);
        this.storiesUpdateInterval = setTimeout(() => {
          // if(!this.state.mouseMoveOverStory && !this.state.pauseStoryUpdate) {
          //     setTimeout(() => {
          // console.log("initiating update feeds")
          // if(this.props.userData.usertype !== 'None') {
          //   // this.initGetStoriesByPackage(this.props.securitiesRawCollection);
          //   this.updateFeeds(this.storiesUpdateHandle.LastFeedRequest);
          // } else {
          //   this.updateFeeds(this.storiesUpdateHandle.LastFeedRequest);
          // }
          this.initGetStoriesByPackage(this.props.securitiesRawCollection)

          clearTimeout(this.storiesUpdateInterval);
          //     },1000)
          // }
        }, interval);
        break;
      case 2:
        this.storiesUpdateInterval = setTimeout(() => {
          // if(!this.state.mouseMoveOverStory && !this.state.pauseStoryUpdate) {
          // setTimeout(() => {
          // if(this.props.userData.usertype !== 'None') {
          //   // this.initGetStoriesByPackage(this.props.securitiesRawCollection);
          //   this.updateFeeds(this.storiesUpdateHandle.LastFeedRequest);
          // } else {
          //   this.updateFeeds(this.storiesUpdateHandle.LastFeedRequest);
          // }
          this.initGetStoriesByPackage(this.props.securitiesRawCollection)
          clearTimeout(this.storiesUpdateInterval);
          //     },1000)
          // }
        }, interval);

        break;
      case 3:
        if (!this.state.feedReqData.RequestState && StoriesIndex.length) {
          // if(!this.storiesUpdateInterval) {
          console.log("Case 3_1")
          this.storiesUpdateInterval = setTimeout(() => {
            // if(!this.state.mousemoveoverstory && !this.state.pauseStoryUpdate) {
            // setTimeout(() => {
            // if(this.props.userData.usertype !== 'None') {
            //   // this.initGetStoriesByPackage(this.props.securitiesRawCollection);
            //   this.updateFeeds(this.storiesUpdateHandle.LastFeedRequest);
            // } else {
            //   this.updateFeeds(this.storiesUpdateHandle.LastFeedRequest);
            // }
            this.initGetStoriesByPackage(this.props.securitiesRawCollection)
            clearTimeout(this.storiesUpdateInterval);
            // },1000)
            // }
          }, interval);
          // }
        } else if (!this.state.feedReqData.RequestState && !StoriesIndex.length) {
          console.log('Case 3_2')
          clearTimeout(this.storiesUpdateInterval);
          this.storiesUpdateInterval = setTimeout(() => {
            this.setState(
              {
                ...this.state,
                feedReqData: {
                  ...this.state.feedReqData,
                  RequestState: true,
                },
              },
              () => {
                this.storiesUpdateHandle.RequestState = true;
                this.storiesUpdateHandle.InitialFeedRequest = this.state.feedReqData.InitialFeedRequest;
                this.storiesUpdateHandle.LastFeedRequest = this.state.feedReqData.LastFeedRequest;
                this.props.setFeedReqData(this.state.feedReqData);
                // if(this.props.userpreference_NACE) {
                //     // call for analysed stories
                //     this.props.getStories(false, true, false, true);
                //     //call for with curated stories
                //     this.props.getStories(false, true, this.props.userpreference_NACE);
                // } else {
                //     //call for both
                //     this.props.getStories(false, true, this.props.userpreference_NACE, 'mix');
                // }

                const Packages = this.props.securitiesRawCollection
                  .map((itm) => !itm.children.length && itm.Name.toLowerCase() !== 'none' && itm.Name)
                  .filter((itm) => itm);

                if (Packages.length) {
                  Packages.map((pkg) => {
                    if (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) {
                      pkg = 'Company';
                    }
                    this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE);
                    // if(this.props.userpreference_NACE) {
                    //     this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE)
                    //     this.props.getStoriesByPackage(CallingPackage || pkg, !this.props.userpreference_NACE)
                    // } else {
                    //     this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE)
                    // }
                    return null;
                  });
                }

                clearTimeout(this.storiesUpdateInterval);
              }
            );
          }, 3000);
        } else {
          // console.log("Case 3_3 never hit here")
        }

        break;
      case 4:
        this.props.preventStoryUpdate(true);
        clearTimeout(this.storiesUpdateInterval);
        this.storiesUpdateInterval = setTimeout(() => {
          this.setState(
            {
              ...this.state,
              feedReqData: {
                ...this.state.feedReqData,
                RequestState: true,
              },
            },
            () => {
              this.storiesUpdateHandle.RequestState = true;
              this.storiesUpdateHandle.InitialFeedRequest = this.state.feedReqData.InitialFeedRequest;
              this.storiesUpdateHandle.LastFeedRequest = this.state.feedReqData.LastFeedRequest;
              this.props.setFeedReqData(this.state.feedReqData);
              // this.togglePageLoader('stories', StoriesReqHandler.FeedReqData.RequestState)
              // this.props.getStories(false, true, this.props.userpreference_NACE);
              const Packages = this.props.securitiesRawCollection
                .map((itm) => !itm.children.length && itm.Name.toLowerCase() !== 'none' && itm.Name)
                .filter((itm) => itm);
              if (Packages.length) {
                Packages.map((pkg) => {
                  if (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) {
                    pkg = 'Company';
                  }
                  this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE);
                  // if(this.props.userpreference_NACE) {
                  //     this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE)
                  //     this.props.getStoriesByPackage(CallingPackage || pkg, !this.props.userpreference_NACE)
                  // } else {
                  //     this.props.getStoriesByPackage(CallingPackage || pkg, this.props.userpreference_NACE)
                  // }
                  return null;
                });
              }
              this.props.preventStoryUpdate(false);
            }
          );
        }, 60000);
        break;
      default:
        // console.log("Case 3 default big issue")
        break;
    }
  };

  updateFeeds = (LastFeedRequest) => {
    // console.log('Stories Updating', LastFeedRequest)
    let startDate;

    if (this.state.FirstUpdate) {
      startDate =
        this.props.userData.userPackage.toLowerCase() === 'bronze'
          ? moment().utcOffset(this.state.utcOffset).subtract(15, 'minutes')
          : moment().utcOffset(this.state.utcOffset);
      startDate = moment(startDate).subtract(90, 'seconds').format();
    } else {
      startDate = moment(LastFeedRequest).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');
    }

    let nextStartDate =
      this.props.userData.userPackage.toLowerCase() === 'bronze'
        ? moment().utcOffset(this.state.utcOffset).subtract(15, 'minutes').format()
        : moment().utcOffset(this.state.utcOffset).format();
    let endDate = moment(nextStartDate).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');

    //fail safe mechanism for updating story//
    if (
      moment(nextStartDate).diff(moment(LastFeedRequest).utcOffset(this.state.utcOffset).format(), 'seconds') >= 300
    ) {
      // window.location.reload()
      window.location.href = APPPATH.AppUrl;
    }
    //end of fail safe mechanism//

    this.setState(
      {
        ...this.state,
        feedReqData: {
          ...this.state.feedReqData,
          RequestState: true,
          LastFeedRequestEnd: nextStartDate,
          LastFeedRequest: LastFeedRequest,
        },
        FirstUpdate: false,
      },
      () => {
        this.props.setFeedReqData(this.state.feedReqData);
        // console.log('setting Update')
        // console.log('LastFeedRequest: ' + LastFeedRequest)
        // console.log('LastFeedRequestEnd: ' + nextStartDate)
        this.storiesUpdateHandle.RequestState = true;
        this.storiesUpdateHandle.LastFeedRequest = LastFeedRequest;
        this.storiesUpdateHandle.LastFeedRequestEnd = nextStartDate;

        this.props.getStoryUpdates(false, startDate, endDate, this.storiesUpdateHandle);
      }
    );
  };

  captureMouseMoveOverStoryFromHome = (status) => {
    this.setState({ ...this.state, mouseMoveOverStory: status });
  };

  capturePauseStoryUpdateFromHome = (status) => {
    if (status) {
      this.setState({ ...this.state, pauseStoryUpdate: status }, () => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            pauseStoryUpdate: false,
          });
        }, 2000);
      });
    }
  };

  render() {
    return '';
  }
}

const mapStateToComponent = (state) => {
  return {
    postponeupdate: state.settings.postponeupdate,
    lang: state.settings.lang,
    userpreference_NACE: state.app_preferences.preferences.event_importance_filters.Non_Active_Curated_Events.Show_NACE,
    StoriesIndex: state.feeds.StoriesIndex,
    securitiesRawCollection: state.filters.securitiesRawCollection,
    securityFilter: state.filters.securityFilter,
    securitiesFilter: state.filters.securitiesFilter,
    securities: state.filters.securities,
    eventFilter: state.filters.eventFilter,
    req_error: state.error.rootFeeds,
    feedResetHandle: state.feeds.feedResetHandle,
    Show_Gray: state.app_preferences.preferences.event_importance_filters.Gray_Colored_Events.Show_Gray,
    render: state.settings.render,
  };
};
const mapDispatchToProps = {
  getStories,
  getStoryUpdates,
  preventStoryUpdate,
  setFeedReqData,
  getStoriesByPackage,
  resetStoriesResetHandle,
  UpdateInitialColumnLoadCounter
};

export default connect(mapStateToComponent, mapDispatchToProps, null, { forwardRef: true })(StoryContextHandle);
