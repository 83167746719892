import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import StoryListHandle from './storyListHandle';
import SingleStory from './single_story';
import TweetDeckColumnBody from './tweetdeck_column_body';
import Tooltip from '../shared/tooltip/generaltooltip';
import SingleTM from '../trendingmentions/single_TM';
import TMTabs from '../trendingmentions/tmTabs';
import TMTabTitles from '../trendingmentions/tmTabTitles';
import TMSubtitles from '../trendingmentions/tmSubtitles';
import TickerDetails from '../trendingmentions/TickerDetails';
import { APPPATH, TrendingMentionsHelper } from '../../modules/helper.module';
import PreferenceCheckBox from '../shared/preferenceCheckBox/preference_checkbox';
import ColorPreferenceSlider from '../shared/preferenceRangeSlider/preferenceslider';
import SearchedTickerDetails from '../trendingmentions/SearchedTickerDetails';
// import SliderForSavedSearch from './sliderForSavedSearch';
// import SliderForSearch from './sliderForSearch';
import ShowDetailIcon from '../common/showDetailIcon';
import HelperVideo from './helperVideo';
import FavCoin from './favCoin';
// import GoPremiumBanner from '../shared/banners/gopremiumbanner';
import TopTrendingPremiumBanner from '../shared/banners/gopremuimtoptrending';
import MoreWatchlist from '../shared/banners/morewatchlist';
import GoogleAds from '../googleAdsense/googleads';
import InFeedAd from '../googleAdsense/infeedAdComponent';
import GoogleAdsResponsive from '../googleAdsense/googleadsresponsive';
import { connect } from 'react-redux';
import InitialColumnLoader from './initcolumnloader';
import HeadbarIframe from '../shared/headbar/headbar_iframe';
import { updateiframeasset, updateiframeassetsecurity, EnableLoader } from '../../actions/settings.action';
import { deleteSavedSearch_LLU } from '../../actions/search.action';
import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../../static_data/translations';
import EventImportanceMenu from './eventimportance_menu';
// import $ from 'jquery';
// require('jquery-ui')

class TweetDeckColumn extends Component {
  constructor(props) {
    super();
    // console.log(props.cc_Preferences.tmFromWatchlist)
    let colstyle = {
      width: props.colRect['width'],
      left: !isNaN(parseInt(props.colRect['left'].replace('px', ''))) ? props.colRect['left'] : '',
      // top: props.colRect['top'].replace('px', ''),
      zIndex: '1100 !important',
    };
    // console.log(props.listkey, props.colRect)
    this.state = {
      // listcollapse: props.collapseFlag,
      colName: props.listkey,
      colIndex: props.listKeyIndex,
      tweetList: {
        Index: [],
      },
      colstyle: {
        width: props.colRect['width'],
        left: !isNaN(parseInt(props.colRect['left'].replace('px', ''))) ? props.colRect['left'] : '',
        // top: props.colRect['top'].replace('px', ''),
        zIndex: '1100 !important',
      },
      colDrag: {
        status: false,
        relativeX: 0,
        relativeY: 0,
      },
      tmAutoRefresh: true,

      saveSearchToolTip: false,
      deleteSearchToolTip: false,
      ssgrayToolTip: false,
      ssblackToolTip: false,
      naceToolTip: false,
      naceHover: false,
      ssGrayHover: false,
      ssBlackHover: false,
      ssCloseHover: false,
      TMCloseHover: false,
      swGrayHover: false,
      swBlackHover: false,
      pinHover: false,
      coinDetailsHover: false,
      coinDetailsCollapse: props.TickerDetailsCollapse,
      MainEventListcollapse: props.MainEventListcollapse,
      CoinEventListcollapse: props.CoinEventListcollapse,
      SearchListCollapse: props.SearchListCollapse,
      mainEventListcollapseHover: false,
      coinEventListcollapseHover: false,
      searchEventListcollapseHover: false,
      savedSearchEventListcollapseHover: false,
      AllSecFilter: props.getAllSecFilter(),
      // TMColumnActiveTab: props.activeTab,
      TMColumnActiveTab: props.cc_Preferences.activeTab,
      TMDetailsColumnActiveTab: props.cc_Preferences.activeTab,
      TMCoinDetailsColumnActiveTab: props.cc_Preferences.activeTab,
      maxCoinListState: parseInt(props.cc_Preferences.tmListMaxCoinCount),
      // maxCoinListState: props.tmListMaxCoinCount,
      // maxCoinListOutOfState: props.tmtotalmaxcoincount,
      maxCoinListOutOfState: parseInt(props.cc_Preferences.tmtotalmaxcoincount),
      FromWatchlist: props.cc_Preferences.tmFromWatchlist === 'true',
      // FromWatchlist: props.tmFromWatchlist,
      maxcoinmenustate: false,
      iframecolorfiltermenustate: false,
      maxcoinmenu: '',
      iframecolorfiltermenu: '',
      // iframe_showmedium: props.storyState.Show_MEDIUM,
      // iframe_showhigh: props.storyState.Show_HIGH,
      // iframe_showhigher: props.storyState.Show_HIGHER,
      feedlisttotalcount: 0
    };

    this.scrollStop = '';
    this.ttHandle = '';
    this.dtHandle = '';
    this.ssgrayHandle = '';
    this.ssblackHandle = '';
    this.naceHandle = '';
    this.tmarWrap = '';
    this.tcol = React.createRef();
    this.EventListHandle = React.createRef();
    this.CollapseTMDEventListOverlay = React.createRef();
    this.CollapseMainEventListOverlay = React.createRef();
    this.ProTipRef = React.createRef();
    this.TCExpandTipRef = React.createRef();
  }

  componentDidMount() {
    this.EventListHandle.setActiveListIndex();
    if (this.props.listkey === 'Searched Feeds') {
      if (isMobile) {
        if (this.props.newTab && this.props.TabActive) {
          if (this.tcol) {
            setTimeout(() => {
              this.tcol.scrollIntoView({ behavoiur: 'smooth' });
            }, 500);
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isMobile) {
      if (this.props.listkey === 'Searched Feeds') {
        if (this.props.TabActive) {
          setTimeout(() => {
            this.tcol.scrollIntoView({ behavoiur: 'smooth' });
          }, 500);
        }
      }
    }
    // console.log(prevState.tweetList.Index, this.state.tweetList.Index)
    // this.props.UpdateInitialColumnLoadCounter()
  }

  componentWillReceiveProps(nextProps) {
    // console.log(this.state.iframe_showmedium, nextProps.storyState.Show_HIGHER)
    this.setState(
      {
        ...this.state,
        colstyle: {
          ...this.state.colstyle,
          width: nextProps.colRect['width'],
          // left:
          //   this.state.colstyle.left === ''
          //     ? isNaN(parseInt(nextProps.colRect['left'].replace('px', '')))
          //       ? this.state.colstyle.left
          //       : nextProps.colRect['left']
          //     : this.props.colRect.width !== nextProps.colRect.width
          //     ? nextProps.colRect['left']
          //     : this.state.colstyle.left,
          left: !isNaN(parseInt(nextProps.colRect['left'].replace('px', ''))) ? nextProps.colRect['left'] : '',
          // top  : this.state.colstyle.top
          // top  : this.state.colstyle.top === '' ? isNaN(parseInt(nextProps.colRect['top'].replace('px',''))) ? this.state.colstyle.top : nextProps.colRect['top'] : this.props.colRect.width!==nextProps.colRect.width || this.props.colRect.top !== nextProps.colRect['top'] ? nextProps.colRect['top'] : this.state.colstyle.top
        },
        TMColumnActiveTab: nextProps.cc_Preferences.activeTab,
        coinDetailsCollapse: nextProps.TickerDetailsCollapse,
        MainEventListcollapse: nextProps.MainEventListcollapse,
        CoinEventListcollapse: nextProps.CoinEventListcollapse,
        SearchListCollapse: nextProps.SearchListCollapse,
        maxCoinListState: parseInt(nextProps.cc_Preferences.tmListMaxCoinCount),
        maxCoinListOutOfState: parseInt(nextProps.cc_Preferences.tmtotalmaxcoincount),
        FromWatchlist: nextProps.cc_Preferences.tmFromWatchlist === 'true',
        // iframe_showmedium: nextProps.storyState.Show_MEDIUM,
        // iframe_showhigh: nextProps.storyState.Show_HIGH,
        // iframe_showhigher: nextProps.storyState.Show_HIGHER,
        // listcollapse: nextProps.collapseFlag,
      },
      () => {
        // if (nextProps.listkey === 'Searched Feeds') {
        // console.log('here')
        // console.log('yellow:', this.state.iframe_showmedium, 'orange: ', this.state.iframe_showhigh, 'red:', this.state.iframe_showhigher)
        this.EventListHandle.setActiveListIndex();
        // }
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this.ttHandle);
    clearTimeout(this.dtHandle);
    clearTimeout(this.ssgrayHandle);
    clearTimeout(this.ssblackHandle);
    clearTimeout(this.naceHandle);
  }

  handleScroll = (e) => {
    if (!this.state.colName.startsWith('Trending Mentions_')) {
      e.preventDefault();
      e.stopPropagation();
      let col = e.target;
      this.props.togglePopup(false);
      // e.stopPropagation();
      if (isMobile) {
        this.props.lockSwipe();
      }

      clearTimeout(this.scrollStop);
      this.scrollStop = setTimeout(() => {
        this.EventListHandle.CheckScrollAndHandleList(col);
        clearTimeout(this.scrollStop);
      }, 1000);
    } else {
      e.preventDefault();
      e.stopPropagation();
      let col = e.target;
      this.props.togglePopup(false);
      // e.stopPropagation();
      if (isMobile) {
        this.props.lockSwipe();
      }

      clearTimeout(this.scrollStop);
    }

  };

  toggleSaveSearchToolTip = (e, showState) => {
    e.preventDefault();
    if (this.ttHandle) {
      clearTimeout(this.ttHandle);
    }
    if (showState && !isMobile) {
      this.setState(
        {
          ...this.state,
          pinHover: showState,
        },
        () => {
          this.ttHandle = setTimeout(() => {
            this.setState(
              {
                ...this.state,
                saveSearchToolTip: showState,
              },
              () => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                window.Mixpanel.actions.identify(this.props.userData.username);
                window.Mixpanel.actions
                  .track(`Save Search tooltip shown`, window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userData.username,
                    });
                  });
              }
            );
          }, 1000);
        }
      );
    } else {
      this.setState({
        ...this.state,
        saveSearchToolTip: false,
        pinHover: false,
      });
    }
  };

  toggledeleteSearchToolTip = (e, showState) => {
    e.preventDefault();
    if (this.dtHandle) {
      clearTimeout(this.dtHandle);
    }
    if (showState && !isMobile) {
      this.setState(
        {
          ...this.state,
          ssCloseHover: showState,
        },
        () => {
          this.dtHandle = setTimeout(() => {
            this.setState(
              {
                ...this.state,
                deleteSearchToolTip: showState,
              },
              () => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                window.Mixpanel.actions.identify(this.props.userData.username);
                window.Mixpanel.actions
                  .track(`Delete Save Search tooltip shown`, window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userData.username,
                    });
                  });
              }
            );
          }, 1000);
        }
      );
    } else {
      this.setState({
        ...this.state,
        deleteSearchToolTip: false,
        ssCloseHover: false,
      });
    }
  };

  toggleswgrayToolTip = (e, showState) => {
    e.preventDefault();
    if (showState && !isMobile) {
      this.setState({
        ...this.state,
        swGrayHover: showState,
      });
    } else {
      this.setState({
        ...this.state,
        swGrayHover: false,
      });
    }
  };
  toggleswblackToolTip = (e, showState) => {
    e.preventDefault();
    if (showState && !isMobile) {
      this.setState({
        ...this.state,
        swBlackHover: showState,
      });
    } else {
      this.setState({
        ...this.state,
        swBlackHover: false,
      });
    }
  };

  togglessgrayToolTip = (e, showState) => {
    e.preventDefault();
    if (this.ssgrayHandle) {
      clearTimeout(this.ssgrayHandle);
    }
    if (showState && !isMobile) {
      this.setState(
        {
          ...this.state,
          ssGrayHover: showState,
        },
        () => {
          this.ssgrayHandle = setTimeout(() => {
            this.setState(
              {
                ...this.state,
                ssgrayToolTip: showState,
              },
              () => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                window.Mixpanel.actions.identify(this.props.userData.username);
                window.Mixpanel.actions
                  .track(`Change curated events tooltip shown`, window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.window.Mixpanel.actions.people.set({
                      UserName: this.props.userData.username,
                    });
                  });
              }
            );
          }, 1000);
        }
      );
    } else {
      this.setState({
        ...this.state,
        ssgrayToolTip: false,
        ssGrayHover: false,
      });
    }
  };

  togglessblackToolTip = (e, showState) => {
    e.preventDefault();
    if (this.ssblackHandle) {
      clearTimeout(this.ssblackHandle);
    }
    if (showState && !isMobile) {
      this.setState(
        {
          ...this.state,
          ssBlackHover: showState,
        },
        () => {
          this.ssblackHandle = setTimeout(() => {
            this.setState(
              {
                ...this.state,
                ssblackToolTip: showState,
              },
              () => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                window.Mixpanel.actions.identify(this.props.userData.username);
                window.Mixpanel.actions
                  .track(`Change black events tooltip shown`, window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userData.username,
                    });
                  });
              }
            );
          }, 1000);
        }
      );
    } else {
      this.setState({
        ...this.state,
        ssblackToolTip: false,
        ssBlackHover: false,
      });
    }
  };

  togglenaceToolTip = (e, showState) => {
    e.preventDefault();
    if (this.naceHandle) {
      clearTimeout(this.naceHandle);
    }
    if (showState && !isMobile) {
      this.setState(
        {
          ...this.state,
          naceHover: showState,
        },
        () => {
          this.naceHandle = setTimeout(() => {
            this.setState(
              {
                ...this.state,
                naceToolTip: showState,
              },
              () => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                window.Mixpanel.actions.identify(this.props.userData.username);
                window.Mixpanel.actions
                  .track(`Non active curated events tooltip shown`, window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userData.username,
                    });
                  });
              }
            );
          }, 1000);
        }
      );
    } else {
      this.setState({
        ...this.state,
        naceToolTip: false,
        naceHover: false,
      });
    }
  };

  updateTweetList = (list, count, updateTmListIndex = false) => {



    this.setState(
      {
        ...this.state,
        tweetList: list,
        feedlisttotalcount: count
      },
      () => {
        if (updateTmListIndex !== false) {
          let { cc_Preferences, cc_index } = this.props;
          // console.log(this.props.cc_Preferences, cc_index, isProFlag)
          cc_Preferences.listIndex = updateTmListIndex;
          this.props.updateCoinColumnPreferences(cc_Preferences, cc_index, this.props.listkey)
        }
        // console.log(this.state.tweetList)
        // if(this.state.colName === 'Trending Mentions') {
        //   this.props.checkWatchList()
        // }
      }
    );
  };

  toggleAutoRefresh = () => {
    this.setState(
      {
        ...this.state,
        tmAutoRefresh: !this.state.tmAutoRefresh,
      },
      () => {
        this.props.togglePopup(false);
        this.props.updateTMAutoRefresh(this.state.tmAutoRefresh);
      }
    );
  };

  toggleAutoRefreshtooltip = (status) => {
    const containerWidth = 170;
    let ContainerRect = this.tmarWrap.getBoundingClientRect();
    let Pos = {
      left: ContainerRect.left - containerWidth,
      top: ContainerRect.bottom + 10,
    };
    let tipText = 'ON / OFF Auto refresh';
    const containerStyleClass = `infoTip normal-right ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
    if (status) {
      this.props.togglePopup(status, tipText, Pos, containerStyleClass);
    } else {
      this.props.togglePopup(status);
    }
  };

  toggleAllSecFilter = () => {
    this.setState(
      {
        ...this.state,
        AllSecFilter: !this.state.AllSecFilter,
      },
      () => {
        this.props.filterSecurities(this.state.AllSecFilter);
      }
    );
  };

  changeTMFrame = (frame) => {
    this.props.setTMFrame(frame);
  };

  // changeTMTabs = (activeTab) => {
  //   this.props.SetTMActiveTab(this.state.TMColumnActiveTab)
  // }
  // detailsTabChange = (newTab) => {
  //   this.setState({
  //     ...this.state,
  //     TMDetailsColumnActiveTab: newTab,
  //   });
  // };

  closeTmDetailsHover = (e, state) => {
    this.setState({
      ...this.state,
      TMCloseHover: state,
    });
  };

  toggleColTitleToolTip = (e, showState, TipText = false, colType) => {
    if (colType === 'tm' && (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin')) {
      let ContainerRect = this.colTitle.getBoundingClientRect();
      let Pos = {
        left: ContainerRect.left,
        top: ContainerRect.bottom + 10,
        width: 150,
      };
      const containerStyleClass = `infoTip normal-middle ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (showState) {
        if (!isMobile) {
          this.props.togglePopup(showState, TipText, Pos, containerStyleClass);
          window.Mixpanel.MixpanelProps.UserName = this.props.username;
          window.Mixpanel.MixpanelProps.TabName = this.props.TipText;
          window.Mixpanel.actions.identify(this.props.username);
          window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.username,
            });
            window.Mixpanel.actions.resetProp('TabName');
          });
        }
      } else {
        this.props.togglePopup(showState);
      }
    }
  };

  collapseCoinDetailsHover = (e, status) => {
    this.setState({
      ...this.state,
      coinDetailsHover: status,
    });
  };

  collapseCoinEventListHover = (e, status) => {
    this.setState({
      ...this.state,
      coinEventListcollapseHover: status,
    });
  };
  collapseMainEventListHover = (e, status) => {
    this.setState({
      ...this.state,
      mainEventListcollapseHover: status,
    });
  };
  collapseSearchEventListHover = (e, status) => {
    this.setState({
      ...this.state,
      searchEventListcollapseHover: status,
    });
  };
  collapseSavedSearchEventListHover = (e, status) => {
    this.setState({
      ...this.state,
      savedSearchEventListcollapseHover: status,
    });
  };

  collapseEventList = () => {
    // this.setState({
    //   ...this.state,
    //   coinEventListcollapse: !this.state.coinEventListcollapse,
    // });
  };
  collapseMainEventList = () => {
    // this.setState({
    //   ...this.state,
    //   MainEventListcollapse: !this.state.MainEventListcollapse,
    // });
  };
  checkIfColumnIsMetricOrEvent = (colType) => {
    return colType === 'tmd';
  };
  columnCollapse = (ColType) => {
    // searchSavedId = colName.replace('SavedSearch', '');
    const { MainEventListcollapse, SearchListCollapse, CoinEventListcollapse } = this.state;
    // console.log(ColType, MainEventListcollapse, SearchListCollapse, CoinEventListcollapse)
    switch (ColType) {
      case 'main':
        return MainEventListcollapse;
      case 'search':
        return SearchListCollapse;
      case 'tmd':
        return CoinEventListcollapse;
      case 'tmcd':
        return this.props.coindetailprefs.CD_Collapsed_View === 'true';
      default:
        break;
    }
    if (ColType === 'savedsearch') {
      let searchSavedId = this.state.colName.replace('SavedSearch', '');
      return JSON.parse(this.props.storyState.searchSaveData[searchSavedId].SavedDataDetails).columnCollapsePreference;
    }
    return true;
  };

  getMobTabViewStatus = () => {
    let MobViewTab = false;
    // console.log(this.state.colName, this.props.TabActive, this.props.BeforeTabActive, this.props.AfterTabActive)
    if (isMobile) {
      if (
        (typeof this.props.TabActive !== 'undefined' && this.props.TabActive) ||
        (typeof this.props.BeforeTabActive !== 'undefined' && this.props.BeforeTabActive) ||
        (typeof this.props.AfterTabActive !== 'undefined' && this.props.AfterTabActive)
      ) {
        MobViewTab = true;
      }
    }
    return MobViewTab;
  };

  // toggleIFrameColorFilterMenuState = () => {
  //   let ContainerRect = this.iframecolorfilter.getBoundingClientRect();
  //   let Pos = {
  //     right: 20,
  //     // left: ContainerRect.left -30,
  //     // top: ContainerRect.bottom,
  //     top: 40,
  //   };
  //   const { storyState } = this.props;
  //   let content = (
  //     <div>
  //       <Row className={`marg-0 nopad`}>
  //         <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tiphead-coinmenu brdr-bot-gray `}>
  //           {/* <img className="tradeWindowClose-closeicon" style={{width: '15px'}} src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" /> */}
  //           <Row className={`marg-0 nopad`}>
  //             <Col className='bold' style={{ fontSize: '13px', textAlign: 'left', padding: '0px', color: this.props.Theme === 'light' ? "#252525" : "#ffffff" }}>
  //               <span>


  //               </span>
  //             </Col>
  //             <Col xs={1} sm={1} md={1} xl={1} lg={1} className="" style={{ marginTop: '-4px', cursor: 'pointer' }} >
  //               <i className="fa fa-times ttmenuclose" onClick={this.toggleIFrameColorFilterMenuState}></i>
  //             </Col>
  //           </Row>

  //         </Col>
  //       </Row>
  //       <Row className={`marg-0`} style={{ backgroundColor: this.props.Theme === 'light' ? '#ffffff' : '#0f1725', padding: "0px 10px 0px 10px" }}>
  //         <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray `} onClick={() => { }}>
  //           {/* ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'selected' : ''} */}
  //           <div>
  //             <span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
  //             </span>
  //             <span> Critical Events</span>
  //             <span style={{ float: 'right' }}>
  //               {/* <input
  //                 type="checkbox"
  //                 name='redcheckbox'
  //                 value={this.state.iframe_showhigher}
  //                 checked={`${this.state.iframe_showhigher ? 'checked' : ''}`}
  //                 // disabled={true}
  //                 onChange={(e) => {
  //                   console.log(this.props.storyState.Show_HIGH)
  //                   console.log(this.state.iframe_showhigh)
  //                   this.Changeiframecolorfilter(e, 3)
  //                 }}
  //               /> */}
  //               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => this.Changeiframecolorfilter(3)}>
  //                 <g id="icon-tick" transform="translate(16066 -11481)">
  //                   <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={`#1da1f0`} stroke="#1da1f0" strokeWidth="1.5">
  //                     <rect width="22" height="22" rx="0" stroke="none" />
  //                     <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
  //                   </g>
  //                   {
  //                     this.state.iframe_showhigher && (
  //                       <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
  //                     )
  //                   }

  //                 </g>
  //               </svg>
  //             </span>
  //           </div>
  //         </Col>
  //         <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray `} onClick={() => { }}>
  //           {/* ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'selected' : ''} */}
  //           <div>
  //             <span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
  //             </span>
  //             <span> Important Events</span>
  //             <span style={{ float: 'right' }}>
  //               {/* <input
  //                 type="checkbox"
  //                 name='orangecheckbox'
  //                 value={this.state.iframe_showhigh}
  //                 checked={`${this.state.iframe_showhigh ? 'checked' : ''}`}
  //                 onChange={(e) => {
  //                   console.log(this.props.storyState.Show_HIGH)
  //                   console.log(this.state.iframe_showhigh)
  //                   this.Changeiframecolorfilter(e, 2)
  //                 }}
  //               /> */}
  //               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => this.Changeiframecolorfilter(2)}>
  //                 <g id="icon-tick" transform="translate(16066 -11481)">
  //                   <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={`#1da1f0`} stroke="#1da1f0" strokeWidth="1.5">
  //                     <rect width="22" height="22" rx="0" stroke="none" />
  //                     <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
  //                   </g>
  //                   {
  //                     this.state.iframe_showhigh && (
  //                       <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
  //                     )
  //                   }

  //                 </g>
  //               </svg>
  //             </span>
  //           </div>
  //         </Col>
  //         <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem `} onClick={() => { }}>
  //           {/* ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'selected' : ''} */}
  //           <div>
  //             <span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
  //               <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
  //             </span>
  //             <span> Regular Events</span>
  //             <span style={{ float: 'right', paddingBottom: '5px' }}>
  //               {/* <input
  //                 type="checkbox"
  //                 value={this.state.iframe_showmedium}
  //                 name='yellowcheckbox'
  //                 checked={`${this.state.iframe_showmedium ? 'checked' : ''}`}
  //                 // defaultChecked={this.props.storyState.Show_MEDIUM}
  //                 onChange={(e) => {
  //                   console.log(this.props.storyState.Show_MEDIUM)
  //                   console.log(this.state.iframe_showmedium)
  //                   this.Changeiframecolorfilter(e, 1)

  //                 }}
  //               /> */}
  //               <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => this.Changeiframecolorfilter(1)}>
  //                 <g id="icon-tick" transform="translate(16066 -11481)">
  //                   <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={`#1da1f0`} stroke="#1da1f0" strokeWidth="1.5">
  //                     <rect width="22" height="22" rx="0" stroke="none" />
  //                     <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
  //                   </g>
  //                   {
  //                     this.state.iframe_showmedium && (
  //                       <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
  //                     )
  //                   }

  //                 </g>
  //               </svg>
  //             </span>
  //           </div>
  //         </Col>
  //       </Row>
  //     </div>
  //   )
  //   const containerStyleClass = 'crl-tooltipMenu coincount normal-left';
  //   console.log(this.state.iframecolorfiltermenustate)
  //   // this.setState({
  //   //   ...this.state,
  //   //   iframecolorfiltermenustate: !this.state.iframecolorfiltermenustate
  //   // })
  //   this.setState({
  //     ...this.state,
  //     iframecolorfiltermenustate: !this.state.iframecolorfiltermenustate,
  //   }, () => {
  //     if (this.state.iframecolorfiltermenustate) {
  //       this.iframecolorfilterPopup(this.state.iframecolorfiltermenustate, content, Pos, containerStyleClass);
  //     } else {
  //       this.iframecolorfilterPopup(this.state.iframecolorfiltermenustate);
  //     }
  //   });
  // }
  // iframecolorfilterPopup = (showState, view, pos, containerClass) => {
  //   let popup = '';
  //   if (showState) {
  //     popup = (
  //       <div className={`marg-0 ${containerClass} ${!showState ? 'nodisp' : ''}`} style={{ ...pos, zIndex: 1203 }}>
  //         {view}
  //       </div>
  //     );
  //   }
  //   // console.log(popup)
  //   this.setState({
  //     ...this.state,
  //     iframecolorfiltermenu: popup,
  //   });
  // }
  // Changeiframecolorfilter = (magnitude) => {
  //   // console.log(e.currentTarget.value, magnitude)
  //   let value;
  //   switch (magnitude) {
  //     case 1:
  //       value = this.state.iframe_showmedium;
  //       break;
  //     case 2:
  //       value = this.state.iframe_showhigh;
  //       break;
  //     case 3:
  //       value = this.state.iframe_showhigher;
  //       break;
  //     default:
  //       break;
  //   }

  //   this.props.updateUserPreferences_EventImportance_Iframe(magnitude, !value)
  //   // this.setState({
  //   //   ...this.state,
  //   //   iframe_showmedium: magnitude === 1 ? !this.state.iframe_showmedium : this.state.iframe_showmedium,
  //   //   iframe_showhigh: magnitude === 2 ? !this.state.iframe_showhigh : this.state.iframe_showhigh,
  //   //   iframe_showhigher: magnitude === 3 ? !this.state.iframe_showhigher : this.state.iframe_showhigher,
  //   // }, () => {

  //   // })

  // }
  togglemaxCoinState = () => {
    let { maxcoinmenustate, maxCoinListState, maxCoinListOutOfState, FromWatchlist } = this.state;
    let ContainerRect = this.coinListCont.getBoundingClientRect();
    // console.log(ContainerRect)
    let Pos = {
      right: 0,
      // left: ContainerRect.left -30,
      // top: ContainerRect.bottom,
      top: 40,
    };
    let content = (
      <div>
        <Row className={`marg-0 nopad`}>
          <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tiphead-coinmenu brdr-bot-gray `}>
            {/* <img className="tradeWindowClose-closeicon" style={{width: '15px'}} src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" /> */}
            <Row className={`marg-0 nopad`}>
              <Col className='bold' style={{ fontSize: '13px', textAlign: 'left', padding: '0px', color: this.props.Theme === 'light' ? "#252525" : "#ffffff" }}>
                <span>
                  {
                    (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'Select from which Companies' : 'Select Assets List'
                  }

                </span>
              </Col>
              <Col xs={1} sm={1} md={1} xl={1} lg={1} className="" style={{ marginTop: '-4px', cursor: 'pointer' }} >
                <i className="fa fa-times ttmenuclose" onClick={this.togglemaxCoinState}></i>
              </Col>
            </Row>

          </Col>
        </Row >
        <Row className={`marg-0`} style={{ backgroundColor: this.props.Theme === 'light' ? '#ffffff' : '#0f1725', padding: "0px 10px 0px 10px" }}>
          <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'selected' : ''}`} onClick={() => this.changeCoinListCount(10, 1000, true)}>
            <div className="fs12 bold">
              From My Watchlist
              {/* <span>
                <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
              </span> */}
            </div>
            <div className="fs11">
              {
                (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'From the companies in my watchlist with 50 or more daily social media mentions' : 'From my watchlist assets with 50 or more daily social media mentions'
              }

            </div>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray ${maxCoinListState === 10 && maxCoinListOutOfState === 50 ? 'selected' : ''}`} onClick={() => this.changeCoinListCount(10, 50)}>
            <div className="fs12 bold">
              {
                (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'From Largest Companies' : 'From Largest Category Assets'
              }
            </div>
            <div className="fs11">
              {
                (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'Out of the largest 50 or more copanies by market cap' : 'From the largest assets in this category'
              }

            </div>
          </Col>

          <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && !FromWatchlist ? 'selected' : ''}`} onClick={() => this.changeCoinListCount(10, 1000)}>
            <div className="fs12 bold">
              {
                (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'From All Companies' : 'From All Category Assets'
              }

              {/* <span>
                <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
              </span> */}
            </div>
            <div className="fs11">
              {
                (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'Out of all companies with $50M+ by market cap and 50 or more daily social media mentions' : 'Out of all assets in this category'
              }

            </div>
          </Col>

          {/* <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray ${maxCoinListState === 20 && maxCoinListOutOfState === 1000 ? 'selected' : ''}`}onClick={() => this.changeCoinListCount(20, 1000)}>
            <div className="fs12 bold">
              Top Trending 20 (All Coins)
              <span>
                <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
              </span>
            </div>
            <div className="fs11">Show top 20 coins out of all analyzed coins</div>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem ${maxCoinListState === 50 && maxCoinListOutOfState === 1000 ? 'selected' : ''}`} onClick={() => this.changeCoinListCount(50, 1000)} >
            <div className="fs12 bold">
              Top Trending 50 (All Coins)
              <span>
                <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
              </span>
            </div>
            <div className="fs11">Show top 50 coins out of all analyzed coins</div>
          </Col> */}
        </Row>
      </div>
    );
    // // let tipText = 'Event integrity level and number of related feeds: Click for details';
    const containerStyleClass = 'crl-tooltipMenu coincount normal-left';

    this.setState(
      {
        ...this.state,
        maxcoinmenustate: !maxcoinmenustate,
      },
      () => {
        if (this.state.maxcoinmenustate) {
          this.coinLimitPopup(this.state.maxcoinmenustate, content, Pos, containerStyleClass);
        } else {
          this.coinLimitPopup(this.state.maxcoinmenustate);
        }
      }
    );
  };
  coinLimitPopup = (showState, view, pos, containerClass) => {
    // console.log('here')
    let popup = '';
    if (showState) {
      popup = (
        <div className={`marg-0 ${containerClass} ${!showState ? 'nodisp' : ''}`} style={{ ...pos, zIndex: 1203 }}>
          {view}
        </div>
      );
    }
    // console.log(popup)
    this.setState({
      ...this.state,
      maxcoinmenu: popup,
    });
  };
  changeCoinListCount = (count, max, watchlist = false) => {
    let userPackage = this.props.userData.userPackage.toLowerCase();
    let freemuimPermission = true;
    if (userPackage === 'bronze' && max === 1000 && ((!this.props.freeTrial) || (this.props.freeTrial && this.props.daystogo < 0))) {
      freemuimPermission = false;
    }
    this.setState(
      {
        ...this.state,
        // maxCoinListState: !freemuimPermission ? 10 : count,
        maxCoinListState: count,
        // maxCoinListOutOfState: !freemuimPermission ? 50 : max,
        maxCoinListOutOfState: max,
        FromWatchlist: watchlist,
        maxcoinmenustate: false,
        maxcoinmenu: '',
      },
      () => {
        // this.coinLimitPopup(false);
        // console.log(count, max, watchlist)
        let { cc_Preferences, cc_index } = this.props;
        cc_Preferences.tmListMaxCoinCount = count;
        cc_Preferences.tmtotalmaxcoincount = max;
        cc_Preferences.tmFromWatchlist = watchlist;
        // delete cc_Preferences.tmFromWatchlis
        this.props.updateCoinColumnPreferences(cc_Preferences, cc_index, this.props.listkey)
        this.props.changeTopTrendingCoinLimit(count, max, watchlist, cc_Preferences, cc_index);
        // if(watchlist) {
        //   this.props.changeTopTrendingCoinLimit(count, max, watchlist);
        // } else if (!freemuimPermission) {
        //   this.props.toggleConfirmPopup(true);
        //   this.props.changeTopTrendingCoinLimit(10, 50, watchlist);
        // } else {
        //   this.props.changeTopTrendingCoinLimit(count, max, watchlist);
        // }
      }
    );
  };

  probuttonhover = (selected) => {
    let Img = document.getElementById('proIMG');
    // Img.src = `${APPPATH.ImageUrl}/${selected ? 'prodetailsselected' : this.props.cc_Preferences.isPro === 'false' ? 'prodetails' : 'prodetailsselected'}.svg`
    // Img.src = `${APPPATH.ImageUrl}/${selected ? 'storyexpandiconblue' : this.props.cc_Preferences.isPro === 'false' ? 'storyexpandicon' : 'storyexpandiconblue'}.svg`

    Img.src = this.props.Theme === 'light' ?
      selected ?
        `${APPPATH.ImageUrl}/storyexpandiconbluelightmode.svg`
        :
        this.props.cc_Preferences.isPro === 'false' ?
          `${APPPATH.ImageUrl}/storyexpandiconlightmode.svg` :
          `${APPPATH.ImageUrl}/storyexpandiconbluelightmode.svg`
      :
      selected ?
        `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
        :
        this.props.cc_Preferences.isPro === 'false' ?
          `${APPPATH.ImageUrl}/storyexpandicon.svg` :
          `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
    // Img.src = `${APPPATH.ImageUrl}/storyexpandicon.svg`
  }

  resizeHeightFromAdSlots = () => {



    let viewPortHeight = this.props.homeviewport.height;
    let { colName } = this.state;
    let { width, left } = this.state.colstyle;
    // console.log(viewPortHeight, colName)
    if (colName === 'Main Feeds') {
      document.getElementById('mainCScroll').setAttribute("style", `max-height:${this.props.homeviewport.height}px`);
      document.getElementById('mainCScroll').setAttribute("style", `height:${this.props.homeviewport.height - 50}px`);

    } else if (colName === 'Trending Mentions Details') {
      document.getElementById('tmdCScroll').setAttribute("style", `max-height:${this.props.homeviewport.height}px`);
      document.getElementById('tmdCScroll').setAttribute("style", `height:${this.props.homeviewport.height - 50}px`);

    }
    // console.log(this.tcol.style.width, width, left)
    this.tcol.style.width = width;
    this.tcol.style.left = left;


  }

  TM_changeIsPro = (isProFlag) => {

    let { cc_Preferences, cc_index } = this.props;
    // console.log(this.props.cc_Preferences, cc_index, isProFlag, this.props.userData)
    cc_Preferences.isPro = !(isProFlag === 'true');
    this.props.updateCoinColumnPreferences(cc_Preferences, cc_index, this.props.listkey)
    // this.props.togglePro();
  }

  collapseCoinDetailColumnList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(this.state.CoinEventListcollapse)
    // this.setState({
    //     ...this.state,
    //     CoinEventListcollapse: !this.state.CoinEventListcollapse,
    // },() => {
    //     // console.log(this.state.CoinEventListcollapse);

    // });
    this.props.toggleCoinDetailColumnCollapse(!(this.props.coindetailprefs.CD_Collapsed_View === 'true'), this.props.coinColumnIndex, this.props.listkey);
  };



  render() {
    const {
      colName,
      colIndex,
      tweetList,
      colstyle,
      saveSearchToolTip,
      deleteSearchToolTip,
      ssgrayToolTip,
      ssblackToolTip,
      naceToolTip,
      pinHover,
      ssCloseHover,
      TMCloseHover,
      coinDetailsHover,
      coinDetailsCollapse,
      coinEventListcollapseHover,
      CoinEventListcollapse,
      MainEventListcollapse,
      mainEventListcollapseHover,
      SearchListCollapse,
      searchEventListcollapseHover,
      savedSearchEventListcollapseHover,
      maxCoinListState,
      maxCoinListOutOfState,
      FromWatchlist,
      maxcoinmenu,
      iframecolorfiltermenu
    } = this.state;
    // console.log(this.state.feedlisttotalcount)
    const { storyState, TickerData } = this.props;
    let FeedIndexer = tweetList.Index;
    let searchSavedId = '';
    let withBlack = false;
    let withCurated = false;

    let ColType = '';
    let callFor;
    let ColName = '';
    let CoinMenuText = '';
    let tmEventTitle = '';
    let TickerIcon = '';
    let colNameCharlength = '';

    switch (colName) {
      case 'Main Feeds':
        ColType = 'main';
        ColName = this.props.preferencesMainstreamNews ? 'My Smart Inbox' : 'My Smart Inbox';
        colNameCharlength = ColName.length
        break;
      case 'Breaking Feeds':
        ColType = 'notify';
        ColName = 'Early Event Alerts';
        colNameCharlength = ColName.length
        break;
      case 'Searched Feeds':
        ColType = 'search';
        callFor = 'search';
        ColName = 'Results';
        colNameCharlength = ColName.length
        tmEventTitle = this.props.TickerName ? `${this.props.TickerName} Events` : 'Events';
        break;
      case 'Analyzed Feeds':
        ColType = 'analyzed';
        ColName = storyState.showEarlyInAnalyzedEvents ? 'All My Analyzed Events' : 'My Additional Analyzed Events';
        colNameCharlength = ColName.length
        break;

      case 'Trending Mentions Details':
        ColType = 'tmd';
        callFor = 'coin-column';
        ColName =
          Object.keys(this.props.TickerData).length
            ? this.props.TickerData.SecurityName !== null
              ? `${this.props.TickerData.SecurityName}`
              : 'Daily Metrics'
            : 'Daily Metrics';
        colNameCharlength = ColName.length
        tmEventTitle = 'Recent Events';
        // console.log(this.props.TickerData)
        // tmEventTitle = this.props.TickerSymbol ? `$${this.props.TickerSymbol} Events` : 'Events';
        TickerIcon =
          Object.keys(this.props.TickerData).length
            ? `${this.props.TickerData.SecurityName !== null
              ? this.props.TickerData.SecurityName.toLowerCase().replace(/ /g, '_')
              : ''
            }`
            : false;
        break;
      default:
        break;
    }
    if (colName.startsWith('Trending Mentions_')) {
      ColType = 'tm';
      callFor = 'top-daily-coins';
      colNameCharlength = 29
      CoinMenuText = maxCoinListState === 10 && maxCoinListOutOfState === 50 ? 'Largest Coins' :
        maxCoinListState === 10 && maxCoinListOutOfState === 1000 && !FromWatchlist ? 'All Coins' :
          maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'My Watchlist' : '';

      ColName = (
        <div
          className={`dispinb `}
        >
          <span
            className={`${isMobile && colNameCharlength > 29 ? 'col-title-ellipses'
              : !isMobile && colNameCharlength > 29 ? 'col-title-ellipses' : ''}`}
            ref={(el) => (this.colTitle = el)}
            onMouseEnter={(e) =>
              this.toggleColTitleToolTip(
                e,
                true,
                'Top Trending Coins classified by different social media metrics in the past 24 hours',
                ColType
              )
            }
            onMouseLeave={(e) => {
              this.toggleColTitleToolTip(e, false, false, ColType);
            }}
          >
            {
              (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? this.props.cc_Preferences.expanded === 'true' ? isMobile ? `Top Trending (Daily)` : `Top Trending (Daily) | Expanded View` : `Top Trending (Daily)` : `${this.props.cc_Preferences.columnTitle}`
            }
            {/* {`${this.props.cc_Preferences.columnTitle}`} */}


          </span>
          &nbsp;

        </div>
      );
    }
    if (colName.startsWith('Coin_Column_details_')) {
      // console.log(this.props.coindetailprefData)
      ColType = 'tmcd';
      callFor = 'coin-column';
      ColName = typeof this.props.coindetailprefData.TickerData !== 'undefined' ? typeof this.props.coindetailprefData.TickerData.SecurityName !== 'undefined' && this.props.coindetailprefData.TickerData.SecurityName !== null ? this.props.coindetailprefData.TickerData.SecurityName : 'Daily Metrics' : 'Daily Metrics'
      colNameCharlength = ColName.length
      tmEventTitle = 'Recent Events';
      TickerIcon =
        typeof this.props.coindetailprefData.TickerData !== 'undefined'
          ? `${typeof this.props.coindetailprefData.TickerData.SecurityName !== 'undefined' && this.props.coindetailprefData.TickerData.SecurityName !== null
            ? this.props.coindetailprefData.TickerData.SecurityName.toLowerCase().replace(/ /g, '_')
            : ''
          }`
          : false;
    }
    if (colName.toLowerCase().includes('savedsearch')) {
      ColType = 'savedsearch';
      callFor = 'search';
      searchSavedId = colName.replace('SavedSearch', '');
      ColName =
        typeof storyState.searchSaveData[searchSavedId] !== 'undefined'
          ? storyState.searchSaveData[searchSavedId].SavedDataTitle
          : '';
      colNameCharlength = ColName.length
      withBlack =
        typeof storyState.searchSaveData[searchSavedId] !== 'undefined' &&
        JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withBlack;
      withCurated =
        typeof storyState.searchSaveData[searchSavedId] !== 'undefined' &&
        JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withCurated;
    }
    let StoryList = [];

    if (ColType !== 'tm') {
      if (FeedIndexer.length) {
        if (TrendingMentionsHelper.FreeSubscriptionFilter()) {
          StoryList.push(
            <TopTrendingPremiumBanner
              key={'tmGoPremium'}
              toggleConfirmPopup={this.props.toggleConfirmPopup}
              popup={true}
              text={'For real time updates'}
              ColType={'watchlist'}
              showModal={this.props.showModal}
            />);
        }
        let adcount = 0;
        let FeedSlotAdArray = ColName === 'My Watchlist Events' ? this.props.FeedAdSlotIds['A'] : colName === 'Trending Mentions Details' ? this.props.FeedAdSlotIds['B'] : [];
        let usedAdSlotIds = [];
        FeedIndexer.map((stryIndex, count) => {
          // console.log(count)
          if (count === 5 || count === 9) {
            if (TrendingMentionsHelper.FreeSubscriptionFilter()) {
              // if(count === adcount+10) {
              let SlotId = '';
              let AdloopFlag = true;
              FeedSlotAdArray.forEach((itm) => {
                if (AdloopFlag) {
                  if (usedAdSlotIds.indexOf(itm) === -1) {
                    SlotId = itm;
                    usedAdSlotIds.push(itm);
                    AdloopFlag = false;
                  }
                }

              })

              if (SlotId !== '') {


                if (ColName === 'My Watchlist Events') {
                  StoryList.push(<InFeedAd key={`${SlotId}${count}`} count={count} resize={this.props.resize} SlotId={SlotId} LayoutKey={'-ga+3h+7v-a2-6i'} resizeColumn={true} resizeHeightFromAdSlots={this.resizeHeightFromAdSlots} />)
                } else if (colName === 'Trending Mentions Details') {
                  StoryList.push(<InFeedAd key={`${SlotId}${count}`} count={count} resize={this.props.resize} SlotId={SlotId} LayoutKey={'-ga+3h+7v-a2-6i'} resizeColumn={true} resizeHeightFromAdSlots={this.resizeHeightFromAdSlots} />)
                }
              }


              // }
            }
          }

          StoryList.push(
            <SingleStory
              {...this.props}
              checkInSearch={storyState.checkInSearch}
              key={`${stryIndex.FeedId}${count}`}
              id={stryIndex.FeedId}
              TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
              tabmode={storyState.TabMode}
              tdecktype={`${ColType}`}
              SavedId={searchSavedId}
              withBlack={withBlack}
              withCurated={withCurated}
              storyCount={FeedIndexer.length}
              // coinEventListcollapse={CoinEventListcollapse}
              // MainEventListcollapse={MainEventListcollapse}
              columnCollapse={this.columnCollapse(ColType)}
              coinColumnIndex={ColType === 'tmcd' ? this.props.coinColumnIndex : null}
              SecurityName={ColType === 'tmcd' ? this.props.SecurityName : ColType === 'tmd' ? this.props.TickerData.SecurityName : null}
              SourceFilter={ColType === 'tmcd' ? this.props.SourceFilter && this.props.SourceFilter !== '' ? this.props.SourceFilter : '' : ''}
            // listcollapse={this.state.listcollapse}
            />
          );
          return null;
        });
      } else {
        StoryList.push(
          <SingleStory
            {...this.props}
            checkInSearch={storyState.checkInSearch}
            key={`${ColType}null`}
            tdecktype={`${ColType}`}
            SavedId={searchSavedId}
            withBlack={withBlack}
            withCurated={withCurated}
            storyCount={FeedIndexer.length}
            columnCollapse={this.columnCollapse(ColType)}
            coinColumnIndex={ColType === 'tmcd' ? this.props.coinColumnIndex : null}
            SecurityName={ColType === 'tmcd' ? this.props.SecurityName : ColType === 'tmd' ? this.props.TickerData.SecurityName : null}
            SourceFilter={ColType === 'tmcd' ? this.props.SourceFilter && this.props.SourceFilter !== '' ? this.props.SourceFilter : '' : ''}
          // coinEventListcollapse={CoinEventListcollapse}
          // MainEventListcollapse={this.props.MainEventListcollapse}
          // listcollapse={this.state.listcollapse}
          />
        );
      }
    } else {
      // let minifyIcon =
      //   !this.props.storyState.TabMode && this.props.colLength === 3
      //     ? true
      //     : this.props.homeviewport.width < 600
      //     ? true
      //     : false;
      // if (ColType === 'tm') {

      // } else {
      if (FeedIndexer.length) {
        FeedIndexer.map((stryIndex, count) => {
          StoryList.push(
            <SingleStory
              {...this.props}
              checkInSearch={storyState.checkInSearch}
              key={`${stryIndex.FeedId}${count}`}
              id={stryIndex.FeedId}
              TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
              tabmode={storyState.TabMode}
              tdecktype={`${ColType}`}
              SavedId={searchSavedId}
              withBlack={withBlack}
              withCurated={withCurated}
              storyCount={FeedIndexer.length}
              // coinEventListcollapse={CoinEventListcollapse}
              // MainEventListcollapse={this.props.MainEventListcollapse}
              // listcollapse={this.state.listcollapse}
              columnCollapse={this.columnCollapse(ColType)}
              coinColumnIndex={ColType === 'tmcd' ? this.props.coinColumnIndex : null}
              SecurityName={ColType === 'tmcd' ? this.props.SecurityName : ColType === 'tmd' ? this.props.TickerData.SecurityName : null}
              SourceFilter={ColType === 'tmcd' ? this.props.SourceFilter && this.props.SourceFilter !== '' ? this.props.SourceFilter : '' : ''}
            />
          );
          return null;
        });
      } else {
        StoryList.push(
          <SingleStory
            {...this.props}
            checkInSearch={storyState.checkInSearch}
            key={`${ColType}null`}
            tdecktype={`${ColType}`}
            SavedId={searchSavedId}
            withBlack={withBlack}
            withCurated={withCurated}
            storyCount={FeedIndexer.length}
            // coinEventListcollapse={CoinEventListcollapse}
            // MainEventListcollapse={this.props.MainEventListcollapse}
            // listcollapse={this.state.listcollapse}
            columnCollapse={this.columnCollapse(ColType)}
            coinColumnIndex={ColType === 'tmcd' ? this.props.coinColumnIndex : null}
            SecurityName={ColType === 'tmcd' ? this.props.SecurityName : ColType === 'tmd' ? this.props.TickerData.SecurityName : null}
            SourceFilter={ColType === 'tmcd' ? this.props.SourceFilter && this.props.SourceFilter !== '' ? this.props.SourceFilter : '' : ''}
          />
        );
      }
      // }
    }

    let TabDomHeight = this.props.storyState.TabMode ? 0 : 0;
    let TmTabMinify =
      !this.props.storyState.TabMode && this.props.colLength === 3
        ? true
        : this.props.homeviewport.width < 600
          ? true
          : false;

    let MobTabViewStatus = isMobile ? this.getMobTabViewStatus() : true;
    let FScrollSTYLE = {};
    if (ColType === 'tmd') {
      FScrollSTYLE.maxHeight = '590px';
      FScrollSTYLE.overflowY = 'auto';
      FScrollSTYLE.overflowX = 'hidden';
    }
    let TmdViewArr = [];
    if (ColType === 'tmd') {
      // console.log(parseInt(TickerData.MarketCapUsd))
      if (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') {
        if (parseInt(TickerData.MarketCapUsd) > 10000000) {
          TmdViewArr.push(
            <div key={`coinmetricsection`} className={`${coinDetailsCollapse ? 'nodisp' : ''} `}>
              <TickerDetails
                {...this.props}
                count={0}
                id={Object.keys(this.props.TickerData).length ? TickerData.SummarizationItemId : ''}
                TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
                tabmode={storyState.TabMode}
                tdecktype={`${ColType}`}
                activeTab={this.state.TMDetailsColumnActiveTab}
                minifyIcon={TmTabMinify}
                userData={this.props.userData}
              />
            </div>
          );
        }
      } else {
        TmdViewArr.push(
          <div key={`coinmetricsection`} className={`${coinDetailsCollapse ? 'nodisp' : ''} `}>
            <TickerDetails
              {...this.props}
              count={0}
              id={Object.keys(this.props.TickerData).lengths ? TickerData.SummarizationItemId : ''}
              TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
              tabmode={storyState.TabMode}
              tdecktype={`${ColType}`}
              activeTab={this.state.TMDetailsColumnActiveTab}
              minifyIcon={TmTabMinify}
              userData={this.props.userData}
            />
          </div>
        );
      }


      TmdViewArr.push(
        <div className="tdeckColWrap " key={`coinrelatedeventsection`} style={{ paddingLeft: '3px', paddingRight: '3px' }}>
          <Row className={`marg-0 nopad TDeck-window-head  tmdbody margb3`}>
            <Col className={`nopad TDeck-window-head-content-title tmdbody align-self-center`}>
              {/* <span className="padl10">
                  <img src={`${APPPATH.CoinIconUrl}/${TickerIcon}.png`} className="tmRowTickerIcon imground" alt="" />
                </span> */}
              <span className={`padl10 padt10 tabSubHead `}>{tmEventTitle}</span>
              <span className="float-right padr10 pointer">
                <ColorPreferenceSlider
                  updateAction={(color, preference) => this.props.updateSearchPreference(color, preference, ColType)}
                  colorCodeValue={{
                    [`#000000`]: {
                      CheckStatus: storyState.CDSearch_with_black,
                      ColorStartPerc: 0,
                      ColorStopPerc: 20,
                      tooltip: 'Include black events that passed initial filtering',
                    },
                    [`#787878`]: {
                      CheckStatus: storyState.CDSearch_with_gray,
                      ColorStartPerc: 21,
                      ColorStopPerc: 40,
                      tooltip: 'Include gray events from curated sources',
                    },
                    [`#ffcf95`]: {
                      CheckStatus: storyState.CDSearch_with_yellow,
                      ColorStartPerc: 41,
                      ColorStopPerc: 60,
                      tooltip:
                        'Include yellow events with at least 100K followers or 2 sources with 1 credible',
                    },
                    [`#ff7f27`]: {
                      CheckStatus: storyState.CDSearch_with_orange,
                      ColorStartPerc: 61,
                      ColorStopPerc: 80,
                      tooltip:
                        'Include orange events with at least 1M followers or 3 sources with 1 credible',
                    },
                    [`#d24242`]: {
                      CheckStatus: storyState.CDSearch_with_red,
                      ColorStartPerc: 81,
                      ColorStopPerc: 100,
                      tooltip:
                        'Include red events with at least 10M followers or 4 sources with 1 credible',
                    },
                  }}
                  togglePopup={this.props.togglePopup}
                  username={this.props.userData.username}
                  usertype={this.props.userData.usertype}
                />
                <span className="dispinb posrelative">
                  <span
                    ref={this.CollapseTMDEventListOverlay}
                    style={{ position: 'relative', padding: 5 }}
                    onMouseEnter={(e) => {
                      let ToolTipStyleData = {
                        Arrow: {
                          marginLeft: '20px',
                        },
                        ToolTip: {
                          align: 'right',
                        },
                      };
                      this.props.toggleOverlay(
                        true,
                        this.CollapseTMDEventListOverlay,
                        'Click to show or hide event data',
                        ToolTipStyleData
                      );
                    }}
                    onMouseLeave={(e) => this.props.toggleOverlay(false)}
                  >
                    <span
                      onMouseEnter={(e) => {
                        this.collapseCoinEventListHover(e, true);
                      }}
                      onMouseLeave={(e) => {
                        this.collapseCoinEventListHover(e, false);
                      }}
                      onClick={this.props.collapseCoinColumnList}
                      style={{ marginLeft: 5 }}
                    >
                      <img
                        style={{ width: '15px' }}
                        src={`${coinEventListcollapseHover
                          ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                          : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                          }`}
                        className={`storyExpandIcon ${!CoinEventListcollapse ? '' : 'transform180anticlock'}`}
                        alt=""
                      />
                    </span>
                  </span>
                </span>
              </span>
            </Col>
          </Row>
          <TweetDeckColumnBody
            {...this.props}
            ColType={ColType}
            handleScroll={this.handleScroll}
            coinDetailsCollapse={coinDetailsCollapse}
            TickerData={TickerData}
          >
            {StoryList}
          </TweetDeckColumnBody>
          {/* <div
              className={`cScroll ${ColType === 'tm' ? 'tm' : ''} `}
              style={{
                height: `${
                  ColType === 'tm'
                    ? this.props.homeviewport.height - TabDomHeight - 130
                    : ColType === 'tmd'
                    ? coinDetailsCollapse
                      ? this.props.homeviewport.height - TabDomHeight - 90
                      : TickerData.CoinDescription !== null
                      ? this.props.homeviewport.height - TabDomHeight - 240
                      : this.props.homeviewport.height - TabDomHeight - 200
                    : this.props.homeviewport.height - TabDomHeight - 50
                }px`,
                overflow: 'auto',
              }}
              onScroll={this.handleScroll}
            >
              
              {StoryList}
            </div> */}
        </div>
      );
    }

    let tmcdArr = [];

    if (ColType === 'tmcd') {
      // console.log(this.props.coindetailprefs)
      if (typeof this.props.coindetailprefData.TickerData !== 'undefined') {
        tmcdArr.push(
          <div key={`coinmetricsection`} className={`${coinDetailsCollapse ? 'nodisp' : ''} `}>
            <TickerDetails
              {...this.props}
              count={0}
              id={this.props.coindetailprefData.TickerData.SummarizationItemId}
              TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
              tabmode={storyState.TabMode}
              tdecktype={`${ColType}`}
              activeTab={this.state.TMCoinDetailsColumnActiveTab}
              minifyIcon={TmTabMinify}
              userData={this.props.userData}
              coinColumnIndex={ColType === 'tmcd' ? this.props.coinColumnIndex : null}
              SecurityName={ColType === 'tmcd' ? this.props.SecurityName !== '' ? this.props.SecurityName : null : null}
              SourceFilter={ColType === 'tmcd' ? this.props.SourceFilter && this.props.SourceFilter !== '' ? this.props.SourceFilter : '' : ''}
              metric={ColType === 'tmcd' ? typeof this.props.coindetailprefs.metric !== 'undefined' ? this.props.coindetailprefs.metric : '' : ''}
            />
          </div>
        )
      }


      tmcdArr.push(
        <div className={`tdeckColWrap  ${this.props.Theme === 'light' ? 'lightmode' : ''}`} key={`coindetailrelatedeventsection${this.props.coinColumnIndex}`} style={{ paddingLeft: '3px', paddingRight: '3px' }}>
          <Row className={`marg-0 nopad TDeck-window-head tmdbody margb3`}>
            <Col className={`nopad TDeck-window-head-content-title tmdbody align-self-center`}>
              {/* <span className="padl10">
                  <img src={`${APPPATH.CoinIconUrl}/${TickerIcon}.png`} className="tmRowTickerIcon imground" alt="" />
                </span> */}
              <span className={`padl10 padt10 tabSubHead `}>{tmEventTitle}</span>
              <span className="float-right padr10 pointer">
                <ColorPreferenceSlider
                  updateAction={(color, preference) => this.props.updateCoinDetailColumnSliderPreference(color, preference, this.props.coinColumnIndex, this.props.SecurityName, this.props.listkey)}
                  colorCodeValue={{
                    [`#000000`]: {
                      CheckStatus: this.props.coindetailprefs.CDSearch_with_black,
                      ColorStartPerc: 0,
                      ColorStopPerc: 16.66,
                      tooltip: 'Include black events that passed initial filtering',
                    },
                    [`#787878`]: {
                      CheckStatus: this.props.coindetailprefs.CDSearch_with_gray,
                      ColorStartPerc: 16.67,
                      ColorStopPerc: 33.33,
                      tooltip: 'Include gray events from curated sources',
                    },
                    [`#e9edf6`]: {
                      CheckStatus: this.props.coindetailprefs.CDSearch_with_white,
                      ColorStartPerc: 33.34,
                      ColorStopPerc: 50,
                      tooltip: 'Include white events with links to a credible source',
                    },
                    [`#ffcf95`]: {
                      CheckStatus: this.props.coindetailprefs.CDSearch_with_yellow,
                      ColorStartPerc: 50.01,
                      ColorStopPerc: 66.67,
                      tooltip:
                        'Include yellow events with at least 100K followers or 2 sources with 1 credible',
                    },
                    [`#ff7f27`]: {
                      CheckStatus: this.props.coindetailprefs.CDSearch_with_orange,
                      ColorStartPerc: 66.67,
                      ColorStopPerc: 83.34,
                      tooltip:
                        'Include orange events with at least 1M followers or 3 sources with 1 credible',
                    },
                    [`#d24242`]: {
                      CheckStatus: this.props.coindetailprefs.CDSearch_with_red,
                      ColorStartPerc: 83.35,
                      ColorStopPerc: 100,
                      tooltip:
                        'Include red events with at least 10M followers or 4 sources with 1 credible',
                    },
                  }}
                  togglePopup={this.props.togglePopup}
                  username={this.props.userData.username}
                  usertype={this.props.userData.usertype}
                />
                <span className="dispinb posrelative">
                  <span
                    // ref={this.CollapseTMDEventListOverlay}
                    style={{ position: 'relative', padding: 5 }}
                  // onMouseEnter={(e) => {
                  //   let ToolTipStyleData = {
                  //     Arrow: {
                  //       marginLeft: '20px',
                  //     },
                  //     ToolTip: {
                  //       align: 'right',
                  //     },
                  //   };
                  //   this.props.toggleOverlay(
                  //     true,
                  //     this.CollapseTMDEventListOverlay,
                  //     'Click to show or hide event data',
                  //     ToolTipStyleData
                  //   );
                  // }}
                  // onMouseLeave={(e) => this.props.toggleOverlay(false)}
                  >
                    <span
                      // onMouseEnter={(e) => {
                      //   this.collapseCoinEventListHover(e, true);
                      // }}
                      // onMouseLeave={(e) => {
                      //   this.collapseCoinEventListHover(e, false);
                      // }}
                      onClick={this.collapseCoinDetailColumnList}
                      style={{ marginLeft: 5 }}
                    >
                      <img
                        style={{ width: '15px' }}
                        // src={`${
                        //   coinEventListcollapseHover
                        //     ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                        //     : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                        // }`}
                        src={`${APPPATH.ImageUrl}/storyexpandicon.svg`}
                        className={`storyExpandIcon ${!(this.props.coindetailprefs.CD_Collapsed_View === 'true') ? '' : 'transform180anticlock'}`}
                        alt=""
                      />
                    </span>
                  </span>
                </span>
              </span>
            </Col>
          </Row>
          <TweetDeckColumnBody
            {...this.props}
            ColType={ColType}
            handleScroll={this.handleScroll}
          // coinDetailsCollapse={coinDetailsCollapse}
          // TickerData={TickerData}
          >
            {StoryList}
          </TweetDeckColumnBody>

        </div>
      );
    }

    let tmheightcorrection = !this.props.cc_Preferences.isPro === 'true' ? 33 : 0
    // console.log(colstyle)
    // console.log(this.props.Theme)
    // console.log(this.props.render, this.props.iframenav);
    // console.log('yellow', storyState.Show_MEDIUM)
    // console.log('orage', storyState.Show_HIGH)
    // console.log('red', storyState.Show_HIGHER)
    return (
      <div
        id={`${colName}${colIndex}`}
        className={`TDeck-window  
          ${storyState.TabMode ? 'tabbed' : ''} 
          ${storyState.UserPreference_RequestState ? 'nodisp' : ''} 
          ${!MobTabViewStatus ? '' : ''}
          ${this.props.render === 'iframe' ? 'iframe' : ''}
          ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''}
        `}
        // ref={`${colName}${colIndex}`}
        ref={(el) => (this.tcol = el)}
        style={colstyle}
      // ${typeof this.props.TabActive !== 'undefined' && !this.props.TabActive ? 'nodisp' : ''}
      >

        <StoryListHandle ref={(el) => { this.EventListHandle = el; }} listkey={colName} updateTweetList={this.updateTweetList} tm_colindex={this.props.cc_index} tmcd_colindex={this.props.coinColumnIndex} hideShowMore={this.hideShowMore} tmcd_SecurityName={this.props.SecurityName} tmcd_SourceFilter={this.props.SourceFilter} />
        {
          this.props.render === 'iframe' && this.props.iframelogo ? (
            <HeadbarIframe />
          ) : ('')
        }
        {/* (this.props.userData.loginlessuser !== 'Iforex') */}
        {
          (this.props.render === 'iframe' && (this.props.iframenav)) && (
            <Row className={`marg-0 nopad TDeck-window-head ${this.props.Theme === 'light' ? 'lightmode' : ''} ${!storyState.TabMode ? (ColType !== 'tm' ? 'margb3' : 'margb3') : ''} ${this.props.iframenav || this.props.iframefilters ? 'iframenav' : ''}`} >
              {
                this.props.iframenav && (
                  <Col xs={8} sm={8} md={8} xl={8} lg={8} className={`nopad TDeck-window-head-content-title align-self-center ${this.props.Theme === 'light' ? 'lightmode ' : ''} ${this.props.iframenav || this.props.iframefilters ? 'iframenav' : ''} ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''}`} >
                    <span
                      className={`pointer ${this.props.iframeasset ? 'iframenavlink' : ''}`}
                      onClick={(e) => {
                        // this.props.EnableLoader()
                        if (this.props.iframeasset) {
                          this.props.deleteSaveSearch(e, this.props.iframeasset);
                          this.props.updateiframeasset(false)
                          this.props.updateiframeassetsecurity(false)
                        }

                      }}
                    >
                      {
                        this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Top AI Events']
                          : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Top AI Events']
                            : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Top AI Events']
                              : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Top AI Events']
                                : 'Top AI Events'
                      }
                    </span>
                    {
                      this.props.iframeasset && (
                        <span>{` > `}</span>
                      )
                    }
                    {
                      this.props.iframeasset && (
                        <span>{this.props.iframeassetdata.CustomSymbol || this.props.iframeassetdata.Name}</span>
                      )
                    }
                  </Col>
                )
              }
              {
                (this.props.iframenav && this.props.iframefilters) && (
                  <EventImportanceMenu updateUserPreferences_EventImportance_Iframe={this.props.updateUserPreferences_EventImportance_Iframe} />
                )
              }

            </Row>
          )
        }
        {
          this.props.render !== 'iframe' && (
            <Row className={`marg-0 nopad TDeck-window-head ${this.props.Theme === 'light' ? 'lightmode' : ''} ${!storyState.TabMode ? (ColType !== 'tm' ? 'margb3' : 'margb3') : ''}`} >
              <Col xs={1} sm={1} md={1} xl={1} lg={1} className={`TDeck-window-head-content-icon ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <div className={`text-white-force`}>
                  {ColType === 'notify' ? (
                    <i className={`fa fa-bell-o pad2px`}></i>
                  ) : ColType === 'analyzed' ? (
                    <i className={`fa fa-shield pad2px`}></i>
                  ) : ColType === 'main' ? (
                    <i className={`fa fa-th-list pad2px`}></i>
                  ) : ColType === 'search' ? (
                    <i className={`fa fa-search pad2px`}></i>
                  ) : ColType === 'savedsearch' ? (
                    <i className={`fa fa-search`}></i>
                  ) : ColType === 'tm' ? (
                    <i className={`fa fa-line-chart`}></i>
                  ) : ColType === 'tmd' ? (
                    // <object className="tmRowTickerIcon imground" data={`${APPPATH.CoinIconUrl}/${TickerIcon}.png`} style={{ marginTop: '-3px' }} type="image/png">
                    <img
                      src={`${APPPATH.CoinIconUrl}/${TickerIcon}.png`}
                      className="tmRowTickerIcon imground"
                      style={{ marginTop: '-3px' }}
                      alt="CrowdSense.ai"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
                      }}
                    />
                    // </object>
                  ) : ColType === 'tmcd' ? (
                    <img
                      src={`${APPPATH.CoinIconUrl}/${TickerIcon}.png`}
                      className="tmRowTickerIcon imground"
                      style={{ marginTop: '-3px' }}
                      alt="CrowdSense.ai"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
                      }}
                    />
                  ) : (
                    // <img src={`${APPPATH.CoinIconUrl}/${TickerIcon}.png`} className="tmRowTickerIcon imground" alt="" />
                    <i className={`fa fa-th pad2px`}></i>
                  )}
                </div>
              </Col>
              <Col className={`nopad TDeck-window-head-content-title align-self-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`} >
                <span
                  style={{ marginTop: '1px' }}
                  className={`text-white-force dispinb ${ColType !== 'tm' ?
                    isMobile && colNameCharlength > 17 ? 'col-title-ellipses'
                      : !isMobile && colNameCharlength > 20 ? 'col-title-ellipses' : ''
                    : 'maxwid75'
                    }`}
                >
                  {ColName}
                  {(callFor === 'coin-column' && (typeof this.props.userData.loginlessuser === 'undefined' || !this.props.userData.loginlessuser)) && (
                    <span className="left-margin-7 pointer">

                      <FavCoin
                        tickerName={this.props.SecurityName}
                        userName={this.props.userData.username}
                        toggleOverlay={this.props.toggleOverlay}
                        toggleConfirmPopup={this.props.toggleConfirmPopup}
                      />

                    </span>
                  )}
                </span>
                {ColType === 'search' && (
                  <span className="float-right padr10 pointer">
                    {!isMobile && (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                      <span className="right-margin-7 pointer">
                        {callFor && <HelperVideo {...this.props} callFor={callFor} />}
                      </span>
                    ) : ('')}
                    {!this.props.hidePreferenceInSearchFlag && (
                      <>
                        <ColorPreferenceSlider
                          updateAction={(color, preference) =>
                            this.props.updateSearchPreference(color, preference, 'search')
                          }
                          colorCodeValue={{
                            [`#000000`]: {
                              CheckStatus: storyState.Search_with_black,
                              ColorStartPerc: 0,
                              ColorStopPerc: 20,
                              tooltip: 'Include black events that passed initial filtering',
                            },
                            [`#787878`]: {
                              CheckStatus: storyState.Search_with_gray,
                              ColorStartPerc: 21,
                              ColorStopPerc: 40,
                              tooltip: 'Include gray events from curated sources',
                            },
                            [`#ffcf95`]: {
                              CheckStatus: storyState.Search_with_yellow,
                              ColorStartPerc: 41,
                              ColorStopPerc: 60,
                              tooltip:
                                'Include yellow events with at least 100K followers or 2 sources with 1 credible',
                            },
                            [`#ff7f27`]: {
                              CheckStatus: storyState.Search_with_orange,
                              ColorStartPerc: 61,
                              ColorStopPerc: 80,
                              tooltip:
                                'Include orange events with at least 1M followers or 3 sources with 1 credible',
                            },
                            [`#d24242`]: {
                              CheckStatus: storyState.Search_with_red,
                              ColorStartPerc: 81,
                              ColorStopPerc: 100,
                              tooltip:
                                'Include red events with at least 10M followers or 4 sources with 1 credible',
                            },
                          }}
                          togglePopup={this.props.togglePopup}
                          username={this.props.userData.username}
                          usertype={this.props.userData.usertype}
                        />
                        <span className="dispinb posrelative spacing">
                          <span
                            onMouseEnter={(e) => {
                              this.collapseSearchEventListHover(e, true);
                            }}
                            onMouseLeave={(e) => {
                              this.collapseSearchEventListHover(e, false);
                            }}
                            onClick={this.props.collapseSearchList}
                            style={{ marginLeft: 5 }}
                          >
                            <img
                              style={{ width: '15px' }}
                              src={`${this.props.Theme === 'light' ?
                                searchEventListcollapseHover
                                  ? `${APPPATH.ImageUrl}/storyexpandiconbluelightmode.svg`
                                  : `${APPPATH.ImageUrl}/storyexpandiconlightmode.svg`
                                :
                                searchEventListcollapseHover
                                  ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                                  : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                                }`}
                              className={`storyExpandIcon ${!SearchListCollapse ? '' : 'transform180anticlock'}`}
                              alt=""
                            />
                          </span>
                        </span>
                      </>
                    )}
                    <span className={`dispinb posrelative spacing`}>
                      <span className="ripplecontainer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 25 25"
                          onMouseEnter={(e) => this.toggleSaveSearchToolTip(e, true)}
                          onMouseLeave={(e) => this.toggleSaveSearchToolTip(e, false)}
                          onClick={() => {
                            this.props.initSaveSearch(true);
                          }}
                        >
                          <path
                            className="a"
                            fill={`${pinHover ? '#50C0FF' : '#96a2ab'}`}
                            stroke={`${pinHover ? '#50C0FF' : '#96a2ab'}`}
                            strokeLinecap={`round`}
                            strokeLinejoin={`round`}
                            strokeMiterlimit={10}
                            strokeWidth={`1.5px`}
                            d="M6.807,14.637H0v-.6c0-1.367,1.213-2.557,3.345-3.3l.991-7.977C2.489,2.307,1.479,1.553,1.479.6V0H14.522V.6c0,.948-1.01,1.7-2.858,2.149l.992,7.978c2.131.743,3.345,1.933,3.345,3.3v.6H9.193L8.006,20.789Z"
                            transform="translate(4.5 2.5)"
                          />
                          <g className="b" fill={`none`} stroke={`#96a2ab`} strokeWidth={2} opacity={0}>
                            <rect className="c" width="25" height="25" rx="12.5" stroke={`none`} />
                            <rect className="d" x="1" y="1" fill={`none`} width="23" height="23" rx="11.5" />
                          </g>
                        </svg>
                      </span>
                      <Tooltip
                        view={`Click here to save the search as a new column`}
                        show={saveSearchToolTip}
                        type={`normal-right`}
                      />
                    </span>
                    <span className="dispinb posrelative spacing">
                      <span
                        onMouseEnter={(e) => {
                          this.closeTmDetailsHover(e, true);
                        }}
                        onMouseLeave={(e) => {
                          this.closeTmDetailsHover(e, false);
                        }}
                        onClick={() => {
                          this.props.resetSearchData();
                        }}
                        style={{ marginLeft: 5 }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828">
                          <g transform="translate(1.414 1.414)">
                            <path
                              className="a "
                              d="M1560.6,103.5l-13.63,13"
                              transform="translate(-1546.97 -103.5)"
                              fill="none"
                              stroke={`${this.props.Theme === 'light' ? TMCloseHover ? '#051993' : '#747474' : TMCloseHover ? '#50C0FF' : '#adadad'}`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2px"
                            />
                            <g transform="translate(-1546.97 -103.5)">
                              <path
                                className="a"
                                d="M1546.97,103.5l13.63,13"
                                fill="none"
                                stroke={`${this.props.Theme === 'light' ? TMCloseHover ? '#051993' : '#747474' : TMCloseHover ? '#50C0FF' : '#adadad'}`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2px"
                              />
                            </g>
                          </g>
                        </svg>
                        <Tooltip view={`Close this column or tab`} show={deleteSearchToolTip} type={`left`} />
                      </span>
                    </span>
                  </span>
                )}
                {ColType === 'savedsearch' && typeof storyState.searchSaveData[searchSavedId] !== 'undefined' && (
                  <>

                    <span className={`float-right padr10 pointer`}>

                      {!this.props.hidePreferenceInSavedSearchFlag[searchSavedId] && (
                        <>
                          <ColorPreferenceSlider
                            updateAction={(color, preference) => {
                              switch (color) {
                                case '#000000':
                                  this.props.updateSavedSearchPreference(
                                    { withBlack: !preference },
                                    storyState.searchSaveData[searchSavedId].UserSavedDataID
                                  );
                                  break;
                                case '#787878':
                                  this.props.updateSavedSearchPreference(
                                    { withCurated: !preference },
                                    storyState.searchSaveData[searchSavedId].UserSavedDataID
                                  );
                                  break;
                                case '#e9edf6':
                                  this.props.updateSavedSearchPreference(
                                    { withWhite: !preference },
                                    storyState.searchSaveData[searchSavedId].UserSavedDataID
                                  );
                                  break;
                                case '#ffcf95':
                                  this.props.updateSavedSearchPreference(
                                    { withYellow: !preference },
                                    storyState.searchSaveData[searchSavedId].UserSavedDataID
                                  );
                                  break;
                                case '#ff7f27':
                                  this.props.updateSavedSearchPreference(
                                    { withOrange: !preference },
                                    storyState.searchSaveData[searchSavedId].UserSavedDataID
                                  );
                                  break;
                                case '#d24242':
                                  this.props.updateSavedSearchPreference(
                                    { withRed: !preference },
                                    storyState.searchSaveData[searchSavedId].UserSavedDataID
                                  );
                                  break;
                                default:
                                  break;
                              }
                            }}
                            colorCodeValue={{
                              [`#000000`]: {
                                CheckStatus: JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withBlack,
                                ColorStartPerc: 0,
                                ColorStopPerc: 16.66,
                                tooltip: 'Include black events that passed initial filtering',
                              },
                              [`#787878`]: {
                                CheckStatus: JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withCurated,
                                ColorStartPerc: 16.67,
                                ColorStopPerc: 33.33,
                                tooltip: 'Include gray events from curated sources',
                              },
                              [`#e9edf6`]: {
                                CheckStatus: JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withWhite,
                                ColorStartPerc: 33.34,
                                ColorStopPerc: 50,
                                tooltip: 'Include white events with links to a credible source',
                              },
                              [`#ffcf95`]: {
                                CheckStatus: JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withYellow,
                                ColorStartPerc: 50.01,
                                ColorStopPerc: 66.67,
                                tooltip:
                                  'Include yellow events with at least 100K followers or 2 sources with 1 credible',
                              },
                              [`#ff7f27`]: {
                                CheckStatus: JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withOrange,
                                ColorStartPerc: 66.67,
                                ColorStopPerc: 83.34,
                                tooltip:
                                  'Include orange events with at least 1M followers or 3 sources with 1 credible',
                              },
                              [`#d24242`]: {
                                CheckStatus: JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).withRed,
                                ColorStartPerc: 83.35,
                                ColorStopPerc: 100,
                                tooltip:
                                  'Include red events with at least 10M followers or 4 sources with 1 credible',
                              },
                            }}
                            togglePopup={this.props.togglePopup}
                            username={this.props.userData.username}
                            usertype={this.props.userData.usertype}
                          />
                          <span className="dispinb posrelative">
                            <span
                              onMouseEnter={(e) => {
                                this.collapseSavedSearchEventListHover(e, true);
                              }}
                              onMouseLeave={(e) => {
                                this.collapseSavedSearchEventListHover(e, false);
                              }}
                              onClick={() => {
                                this.props.toggleCollapseSavedSearchList(
                                  searchSavedId,
                                  !JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails)
                                    .columnCollapsePreference
                                );
                              }}
                              style={{ marginRight: 5 }}
                            >
                              <img
                                style={{ width: '15px', margin: '0 0 0 15px' }}
                                src={`${this.props.Theme === 'light' ?
                                  savedSearchEventListcollapseHover
                                    ? `${APPPATH.ImageUrl}/storyexpandiconbluelightmode.svg`
                                    : `${APPPATH.ImageUrl}/storyexpandiconlightmode.svg`
                                  :
                                  savedSearchEventListcollapseHover
                                    ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                                    : `${APPPATH.ImageUrl}/storyexpandicon.svg`

                                  }`}
                                className={`storyExpandIcon ${!JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails)
                                  .columnCollapsePreference
                                  ? ''
                                  : 'transform180anticlock'
                                  }`}
                                alt=""
                              />
                            </span>
                          </span>
                          {/* <span
                            onClick={() => {
                              this.setState({
                                listcollapse: !this.state.listcollapse,
                              });
                            }}
                          >
                            <ShowDetailIcon coltype="search" listcollapse={this.state.listcollapse} />
                          </span> */}
                        </>
                      )}
                      <span className="dispinb posrelative">
                        <span
                          onMouseEnter={(e) => this.toggledeleteSearchToolTip(e, true)}
                          onMouseLeave={(e) => this.toggledeleteSearchToolTip(e, false)}
                          onClick={(e) => {
                            this.props.deleteSaveSearch(e, searchSavedId);
                          }}
                          style={{ marginLeft: 5 }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828">
                            <g transform="translate(1.414 1.414)">
                              <path
                                className="a"
                                d="M1560.6,103.5l-13.63,13"
                                transform="translate(-1546.97 -103.5)"
                                fill="none"
                                stroke={this.props.Theme === 'light' ? ssCloseHover ? '#051993' : '#747474' : TMCloseHover ? '#50C0FF' : '#adadad'}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2px"
                              />
                              <g transform="translate(-1546.97 -103.5)">
                                <path
                                  className="a"
                                  d="M1546.97,103.5l13.63,13"
                                  fill="none"
                                  stroke={this.props.Theme === 'light' ? ssCloseHover ? '#051993' : '#747474' : TMCloseHover ? '#50C0FF' : '#adadad'}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2px"
                                />
                              </g>
                            </g>
                          </svg>

                          <Tooltip view={`Close this column or tab`} show={deleteSearchToolTip} type={`left`} />
                        </span>
                      </span>
                    </span>
                    {/* <span className="float-right padr10 dispinb posrelative pointer" style={{ marginRight: 10, fontSize: 18 }}>
                        <i className="fa fa-edit" />
                    </span> */}
                  </>
                )}{' '}
                {ColType === 'main' && (
                  <div className={`float-right padr5  pointer`}>
                    <ColorPreferenceSlider
                      updateAction={(color, preference) => this.props.updateUserPreferences_EventImportance(color, preference, this.props.listkey)}
                      colorCodeValue={{
                        [`#787878`]: {
                          CheckStatus: storyState.NACE,
                          ColorStartPerc: 0,
                          ColorStopPerc: 20,
                          tooltip: 'Include gray events from curated sources',
                        },
                        [`#e9edf6`]: {
                          CheckStatus: storyState.Show_LOW,
                          ColorStartPerc: 21,
                          ColorStopPerc: 40,
                          tooltip: 'Include white events with links to a credible source',
                        },
                        [`#ffcf95`]: {
                          CheckStatus: storyState.Show_MEDIUM,
                          ColorStartPerc: 41,
                          ColorStopPerc: 60,
                          tooltip: 'Include yellow events with at least 100K followers or 2 sources with 1 credible',
                        },
                        [`#ff7f27`]: {
                          CheckStatus: storyState.Show_HIGH,
                          ColorStartPerc: 61,
                          ColorStopPerc: 80,
                          tooltip: 'Include orange events with at least 1M followers or 3 sources with 1 credible',
                        },
                        [`#d24242`]: {
                          CheckStatus: storyState.Show_HIGHER,
                          ColorStartPerc: 81,
                          ColorStopPerc: 100,
                          tooltip: 'Include red events with at least 10M followers or 4 sources with 1 credible',
                        },
                      }}
                      togglePopup={this.props.togglePopup}
                      username={this.props.userData.username}
                      usertype={this.props.userData.usertype}
                    />
                    <span
                      className={`secFilterEyeHover nodisp`}
                      onClick={(e) => {
                        this.toggleAllSecFilter(e);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27.5"
                        height="20.41"
                        viewBox="0 0 27.5 20.41"
                        className={`${this.state.AllSecFilter ? 'eyept2' : 'eyept1'}`}
                        style={{ marginLeft: 5 }}
                      >
                        <g id="check-eye-normal" transform="translate(13072.838 -4197.882)">
                          <path
                            id="Path_253"
                            data-name="Path 253"
                            d="M665.456,1345.132s4.727-9.455,13-9.455,13,9.455,13,9.455-4.727,9.455-13,9.455S665.456,1345.132,665.456,1345.132Z"
                            transform="translate(-13737.544 2862.955)"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <circle
                            id="Ellipse_64"
                            data-name="Ellipse 64"
                            cx="3.545"
                            cy="3.545"
                            r="3.545"
                            transform="translate(-13062.633 4204.541)"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_359"
                            data-name="Path 359"
                            d="M677.764,1335.677"
                            transform="translate(-13737.544 2862.955)"
                            fill="none"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </span>
                    <span className="dispinb posrelative">
                      <span
                        ref={this.CollapseMainEventListOverlay}
                        style={{ position: 'relative', padding: 5 }}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '15px',
                            },
                            ToolTip: {
                              align: 'right',
                            },
                          };
                          this.props.toggleOverlay(
                            true,
                            this.CollapseMainEventListOverlay,
                            'Click to show or hide event data',
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                        <span
                          onMouseEnter={(e) => {
                            this.collapseMainEventListHover(e, true);
                          }}
                          onMouseLeave={(e) => {
                            this.collapseMainEventListHover(e, false);
                          }}
                          onClick={this.props.collapseMainEventList}
                          style={{ marginLeft: 5, marginRight: 5 }}
                        >
                          <img
                            style={{ width: '15px' }}
                            src={`${this.props.Theme === 'light' ?
                              mainEventListcollapseHover
                                ? `${APPPATH.ImageUrl}/storyexpandiconbluelightmode.svg`
                                : `${APPPATH.ImageUrl}/storyexpandiconlightmode.svg`
                              :
                              mainEventListcollapseHover
                                ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                                : `${APPPATH.ImageUrl}/storyexpandicon.svg`

                              }`}
                            className={`storyExpandIcon ${!MainEventListcollapse ? '' : 'transform180anticlock'}`}
                            alt=""
                          />
                        </span>
                      </span>
                    </span>
                    <span className="dispinb posrelative padr10">
                      <span
                        // onMouseEnter={(e) => {
                        //   this.closeTmDetailsHover(e, true);
                        // }}
                        // onMouseLeave={(e) => {
                        //   this.closeTmDetailsHover(e, false);
                        // }}
                        onClick={() => { this.props.CloseRecentUpdates() }}
                        style={{ marginLeft: 5 }}
                      >
                        <svg
                          className={`coin-column-close-btn ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16.458 15.828"
                        >
                          <g transform="translate(1.414 1.414)">
                            <path
                              className="a "
                              d="M1560.6,103.5l-13.63,13"
                              transform="translate(-1546.97 -103.5)"
                              fill="none"
                              stroke={`${this.props.Theme === 'light' ? TMCloseHover ? '#051993' : '#747474' : TMCloseHover ? '#50C0FF' : '#adadad'}`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2px"
                            />
                            <g transform="translate(-1546.97 -103.5)">
                              <path
                                className="a"
                                d="M1546.97,103.5l13.63,13"
                                fill="none"
                                stroke={`${this.props.Theme === 'light' ? TMCloseHover ? '#051993' : '#747474' : TMCloseHover ? '#50C0FF' : '#adadad'}`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2px"
                              />
                            </g>
                          </g>
                        </svg>
                        <Tooltip view={`Close this column or tab`} show={deleteSearchToolTip} type={`left`} />
                      </span>
                    </span>
                    <Tooltip
                      view={`Include also gray (curated) events in the column / tab for broader and timely coverage. This might include more noise and old or duplicate events`}
                      show={naceToolTip}
                      type={`normal-right`}
                    />
                  </div>
                )}
                {ColType === 'tmd' && (
                  <div className="float-right padr10 pointer">
                    <span className="dispinb posrelative">
                      {/* <span style={{ marginRight: 5, paddingTop: 5, fontSize: 16, fontWeight: 'bold', color: '#50c0ff' }}>
                        1D
                      </span> */}
                      <span className="dispinb posrelative">
                        {!isMobile && (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                          <span className="right-margin-7 pointer">
                            {callFor && <HelperVideo {...this.props} callFor={callFor} />}
                          </span>
                        ) : ('')}
                      </span>
                    </span>

                    <span className={`dispinb posrelative spacing`}>
                      <span className="ripplecontainer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 25 25"
                          onMouseEnter={(e) => { }}
                          onMouseLeave={(e) => { }}
                          onClick={() => {
                            // this.props.initSaveSearch(true);
                            // console.log('here')
                            // console.log(this.props)
                            this.props.pinCoinColumn()
                          }}
                        >
                          <path
                            className="a"
                            fill={`${pinHover ? '#50C0FF' : '#96a2ab'}`}
                            stroke={`${pinHover ? '#50C0FF' : '#96a2ab'}`}
                            strokeLinecap={`round`}
                            strokeLinejoin={`round`}
                            strokeMiterlimit={10}
                            strokeWidth={`1.5px`}
                            d="M6.807,14.637H0v-.6c0-1.367,1.213-2.557,3.345-3.3l.991-7.977C2.489,2.307,1.479,1.553,1.479.6V0H14.522V.6c0,.948-1.01,1.7-2.858,2.149l.992,7.978c2.131.743,3.345,1.933,3.345,3.3v.6H9.193L8.006,20.789Z"
                            transform="translate(4.5 2.5)"
                          />
                          <g className="b" fill={`none`} stroke={`#96a2ab`} strokeWidth={2} opacity={0}>
                            <rect className="c" width="25" height="25" rx="12.5" stroke={`none`} />
                            <rect className="d" x="1" y="1" fill={`none`} width="23" height="23" rx="11.5" />
                          </g>
                        </svg>
                      </span>

                    </span>

                    <span className="dispinb posrelative">
                      <span
                        onMouseEnter={(e) => {
                          this.closeTmDetailsHover(e, true);
                        }}
                        onMouseLeave={(e) => {
                          this.closeTmDetailsHover(e, false);
                        }}
                        onClick={this.props.CloseTMDetails}
                        style={{ marginLeft: 5 }}
                      >
                        <svg
                          className="coin-column-close-btn"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16.458 15.828"
                        >
                          <g transform="translate(1.414 1.414)">
                            <path
                              className="a "
                              d="M1560.6,103.5l-13.63,13"
                              transform="translate(-1546.97 -103.5)"
                              fill="none"
                              stroke={`${this.props.Theme === 'light' ? '#747474' : '#adadad'}`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2px"
                            />
                            <g transform="translate(-1546.97 -103.5)">
                              <path
                                className="a"
                                d="M1546.97,103.5l13.63,13"
                                fill="none"
                                stroke={`${this.props.Theme === 'light' ? '#747474' : '#adadad'}`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2px"
                              />
                            </g>
                          </g>
                        </svg>
                        <Tooltip view={`Close this column or tab`} show={deleteSearchToolTip} type={`left`} />
                      </span>
                    </span>
                  </div>
                )}
                {ColType === 'tm' && (
                  // <div className="float-right padr10 pointer">

                  <span className="dispinb posrelative float-right padr10" ref={(el) => { this.coinListCont = el; }}>

                    {!isMobile && (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (<span className=" pointer" style={{ marginRight: '7px' }}>{callFor && <HelperVideo {...this.props} callFor={callFor} />}</span>) : ('')}
                    {/* <span style={{ marginRight: 5, paddingTop: 5, fontSize: 16, fontWeight: 'bold', color: '#50c0ff' }}>
                        1D
                      </span> */}
                    {/* advanceTopCoinsSwitch = ( */}
                    {
                      this.props.cc_Preferences.expanded !== 'true' && (
                        <span ref={this.ProTipRef}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '50px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.ProTipRef,
                              'Click to toggle between detailed and non detailed view',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                          className="margsmRight10"
                          style={{ marginRight: '10px', marginLeft: '10px' }}
                        >
                          <img
                            id='proIMG'
                            style={{ width: '15px' }}
                            // src={`${APPPATH.ImageUrl}/${this.props.userData.isPro ? 'prodetailsselected' : 'prodetails'}.svg`}
                            src={`${APPPATH.ImageUrl}/${this.props.Theme === 'light' ? this.props.cc_Preferences.isPro === 'true' ? 'storyexpandiconlightmode.svg' : 'storyexpandiconlightmode.svg' : this.props.cc_Preferences.isPro === 'true' ? 'storyexpandicon.svg' : 'storyexpandicon.svg'}`}
                            className={`helper-image pointer ${this.props.cc_Preferences.isPro === 'true' ? 'transform180anticlock' : ''}`}
                            alt=""
                            // onMouseOver={(e) => this.probuttonhover(true)}
                            // onMouseEnter={() => this.probuttonhover(true)}
                            // onMouseLeave={() => this.probuttonhover(false)}
                            // onClick={() => this.TM_changeIsPro(this.props.userData.isPro) }
                            onClick={() => this.TM_changeIsPro(this.props.cc_Preferences.isPro)}

                          />
                        </span>
                      )
                    }

                    <span ref={this.TCExpandTipRef}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '100px',
                          },
                          ToolTip: {
                            align: 'slightleft',
                          },
                        };
                        this.props.toggleOverlay(
                          true,
                          this.ProTipRef,
                          'Click to toggle between expanded and collapsed view',
                          ToolTipStyleData
                        );
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      className={`margsmRight10 `}
                      style={{ marginRight: '10px', marginLeft: '10px' }}
                      onClick={() => this.props.expandTopCoins(this.props.cc_index, this.props.listkey)}
                    >
                      <i className={`fa fa-external-link ${this.props.Theme === 'light' ? 'lightmode' : ''} pointer ${this.props.cc_Preferences.expanded === 'true' ? 'transform180clock fs17' : 'fs15'}`} />
                    </span>
                    <span className="dispinb posrelative" style={{ marginLeft: '7px' }}>
                      <span
                        onMouseEnter={(e) => {
                          // this.closeTmDetailsHover(e, true);
                        }}
                        onMouseLeave={(e) => {
                          // this.closeTmDetailsHover(e, false);
                        }}
                        onClick={() => { this.props.cc_Preferences.expanded === 'true' ? this.props.expandTopCoins(this.props.cc_index, this.props.listkey) : this.props.closeTopcoins(this.props.cc_index, this.props.listkey) }}//this.props.CloseTMDetails}
                        style={{ marginLeft: '5px' }}
                      >
                        <svg
                          className="coin-column-close-btn pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16.458 15.828"
                        >
                          <g transform="translate(1.414 1.414)">
                            <path
                              className="a "
                              d="M1560.6,103.5l-13.63,13"
                              transform="translate(-1546.97 -103.5)"
                              fill="none"
                              stroke={`${this.props.Theme === 'light' ? '#747474' : '#adadad'}`}//${TMCloseHover ? '#50C0FF' : '#adadad'}`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2px"
                            />
                            <g transform="translate(-1546.97 -103.5)">
                              <path
                                className="a"
                                d="M1546.97,103.5l13.63,13"
                                fill="none"
                                stroke={`${this.props.Theme === 'light' ? '#747474' : '#adadad'}`}//${TMCloseHover ? '#50C0FF' : '#adadad'}`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2px"
                              />
                            </g>
                          </g>
                        </svg>
                        <Tooltip view={`Close this column or tab`} show={deleteSearchToolTip} type={`left`} />
                      </span>
                    </span>

                    {/* <span className="toggle-wrapper" 
                          
                        >
                          
                          {!this.props.userData.isPro && <span style={{ color: '#96a2ab' }}>Pro</span>}
                          {this.props.userData.isPro && <span style={{ color: '#2281b5', fontWeight: 'bold' }}>Pro</span>}

                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() => {
                                this.props.togglePro();
                              }}
                              checked={this.props.userData.isPro}
                            />
                            <span className="slider round"></span>
                          </label>
                        </span> */}

                    {/* ); */}

                    {/* <span className="usercoincount pointer" onClick={this.togglemaxCoinState}>
                        <i className="fa fa-ellipsis-v fs15" style={{paddingLeft: 5, paddingTop: 5}} />
                      </span> */}
                    {/* {maxcoinmenu} */}
                  </span>
                  // </div>
                )}
                {ColType === 'tmcd' && (
                  <div className="float-right padr10 pointer">
                    <span className="dispinb posrelative">
                      {/* <span style={{ marginRight: 5, paddingTop: 5, fontSize: 16, fontWeight: 'bold', color: '#50c0ff' }}>
                        1D
                      </span> */}
                      <span className="dispinb posrelative">
                        {!isMobile && (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                          <span className="right-margin-7 pointer">
                            {callFor && <HelperVideo {...this.props} callFor={callFor} />}
                          </span>
                        ) : ('')}
                      </span>
                    </span>



                    <span className="dispinb posrelative">
                      <span
                        // onMouseEnter={(e) => {
                        //   this.closeTmDetailsHover(e, true);
                        // }}
                        // onMouseLeave={(e) => {
                        //   this.closeTmDetailsHover(e, false);
                        // }}
                        // onClick={() => this.props.CloseCoinDetailColumn(this.props.coinColumnIndex, this.props.SecurityName)}
                        onClick={() => this.props.closecoincolumn(this.props.coinColumnIndex, this.props.SecurityName, this.props.SourceFilter, this.state.colName, this.state.colIndex, this.props.listkey)}
                        style={{ marginLeft: 5 }}
                      >
                        <svg
                          className="coin-column-close-btn"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16.458 15.828"
                        >
                          <g transform="translate(1.414 1.414)">
                            <path
                              className="a "
                              d="M1560.6,103.5l-13.63,13"
                              transform="translate(-1546.97 -103.5)"
                              fill="none"
                              stroke={`${this.props.Theme === 'light' ? '#747474' : '#adadad'}`}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2px"
                            />
                            <g transform="translate(-1546.97 -103.5)">
                              <path
                                className="a"
                                d="M1546.97,103.5l13.63,13"
                                fill="none"
                                stroke={`${this.props.Theme === 'light' ? '#747474' : '#adadad'}`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2px"
                              />
                            </g>
                          </g>
                        </svg>
                        <Tooltip view={`Close this column or tab`} show={deleteSearchToolTip} type={`left`} />
                      </span>
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          )
        }


        {
          ColType === 'tm' ?
            this.props.cc_Preferences.expanded === 'true' ?
              isMobile || this.props.homeviewport.width < 600 ? (
                <div>
                  <TMSubtitles
                    togglemaxCoinState={this.togglemaxCoinState}
                    maxcoinmenu={maxcoinmenu}
                    CoinMenuText={CoinMenuText}
                    cc_index={this.props.cc_index}
                    viewState={'expandedmobile'}
                    usertype={this.props.userData.usertype}
                  />
                  {/* <TMTabs
                    activeTab={this.state.TMColumnActiveTab}
                    changeTMTabs={this.props.SetTMActiveTab}
                    minify={TmTabMinify}
                    togglePopup={this.props.togglePopup}
                    toggleOverlay={this.props.toggleOverlay}
                    username={this.props.userData.username}
                    colType={ColType}
                    // updateCoinColumnPreferences={this.props.updateCoinColumnPreferences}
                    // cc_Preferences={this.props.cc_Preferences}
                    isPro={this.props.cc_Preferences.isPro === 'true'}
                    cc_index={this.props.cc_index}
                    expanded={this.props.cc_Preferences.expanded === 'true'}
                    viewState={'expandedmobile'}
                  /> */}
                  <div
                    style={{
                      minHeight: this.props.homeviewport.height - (75 - tmheightcorrection),
                      height: this.props.homeviewport.height - (75 - tmheightcorrection),

                    }}
                    className={`expScroll`}
                  >
                    <table className={`CoinTable ${this.props.Theme === 'light' ? 'lightmode' : ''} expandedmobile ${(this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) && 'pad'}`}>
                      <thead style={{}}>
                        <TMTabTitles
                          {...this.props}
                          // activeTab={this.state.TMColumnActiveTab}
                          changeTMTabs={this.props.SetTMActiveTab}
                          colType={ColType}
                          minify={TmTabMinify}
                          togglePopup={this.props.togglePopup}
                          toggleOverlay={this.props.toggleOverlay}
                          cc_index={this.props.cc_index}
                          viewState={'expandedmobile'}

                        />
                      </thead>
                      <thead>
                        <tr>
                          <th>

                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          FeedIndexer.length ? (
                            FeedIndexer.map((stryIndex, count) => (

                              <SingleTM
                                {...this.props}
                                index={StoryList.length}
                                count={count + 1}
                                id={stryIndex.FeedId}
                                key={`${stryIndex.FeedId}${count}`}
                                TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
                                tabmode={storyState.TabMode}
                                tdecktype={`${ColType}`}
                                // activeTab={this.state.TMColumnActiveTab}
                                minifyIcon={TmTabMinify}
                                viewState={'expandedmobile'}
                              />

                            ))
                          ) : ('')
                        }
                        {FeedIndexer.length < this.state.feedlisttotalcount && (
                          <tr className={` bordercellright ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                            <td style={{ position: 'sticky', left: '30%', border: 'unset' }} >
                              <Row className={`marg-0 `} key={`tm_showmore`} >
                                <Col className=" align-self-center text-center" style={{ padding: '10px 10px 15px 10px' }}>
                                  <span>
                                    <button
                                      className="btn tryforfree-btn" style={{ padding: 5, paddingLeft: 10, paddingRight: 10 }}
                                      onClick={() => {
                                        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                                        window.Mixpanel.MixpanelProps.Column = 'Top coins';
                                        window.Mixpanel.actions.identify(this.props.userData.username);
                                        window.Mixpanel.actions.track(`Show more clicked in top coins`, window.Mixpanel.MixpanelProps).then((data) => {
                                          window.Mixpanel.actions.people.set({
                                            UserName: this.props.userData.username,
                                          });
                                          window.Mixpanel.actions.resetProp('Column');
                                        });
                                        this.EventListHandle.tm_showMore()
                                      }}
                                    >
                                      Show More
                                    </button>
                                  </span>
                                </Col>
                              </Row>
                            </td>
                            <td colSpan={9} style={{ border: 'unset' }}></td>
                          </tr>
                        )}
                        {
                          !FeedIndexer.length && (
                            <SingleTM
                              {...this.props}
                              index={StoryList.length}
                              key={`${ColType}null`}
                              tdecktype={`${ColType}`}
                              // activeTab={this.state.TMColumnActiveTab}
                              minifyIcon={TmTabMinify}
                              viewState={'expandedmobile'}
                            />
                          )
                        }
                      </tbody>
                    </table>

                  </div>
                </div>

              ) : (
                <div>
                  <TMSubtitles
                    togglemaxCoinState={this.togglemaxCoinState}
                    maxcoinmenu={maxcoinmenu}
                    CoinMenuText={CoinMenuText}
                    cc_index={this.props.cc_index}
                    viewState={'expandeddesktop'}
                    usertype={this.props.userData.usertype}
                  />
                  <div
                    style={{
                      minHeight: this.props.homeviewport.height - (90 - tmheightcorrection),
                      height: this.props.homeviewport.height - (90 - tmheightcorrection),

                    }}
                    className={`expScroll`}
                  >

                    <table className={`CoinTable ${this.props.Theme === 'light' ? 'lightmode' : ''} ${(this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) && 'pad'}`}>
                      <thead style={{}}>
                        {/* <TMTabs
                            activeTab={this.state.TMColumnActiveTab}
                            changeTMTabs={this.props.SetTMActiveTab}
                            minify={TmTabMinify}
                            togglePopup={this.props.togglePopup}
                            toggleOverlay={this.props.toggleOverlay}
                            username={this.props.userData.username}
                            colType={ColType}
                            // updateCoinColumnPreferences={this.props.updateCoinColumnPreferences}
                            // cc_Preferences={this.props.cc_Preferences}
                            isPro={this.props.cc_Preferences.isPro === 'true'}
                            cc_index={this.props.cc_index}
                            expanded={this.props.cc_Preferences.expanded === 'true'}
                            viewState={'expandeddesktop'}
                          /> */}
                        <TMTabTitles
                          {...this.props}
                          // activeTab={this.state.TMColumnActiveTab}
                          changeTMTabs={this.props.SetTMActiveTab}
                          colType={ColType}
                          minify={TmTabMinify}
                          togglePopup={this.props.togglePopup}
                          toggleOverlay={this.props.toggleOverlay}
                          cc_index={this.props.cc_index}
                          viewState={'expandeddesktop'}
                        />
                      </thead>

                      <tbody>
                        {
                          FeedIndexer.length ? (
                            FeedIndexer.map((stryIndex, count) => (
                              <SingleTM
                                {...this.props}
                                index={StoryList.length}
                                count={count + 1}
                                id={stryIndex.FeedId}
                                key={`${stryIndex.FeedId}${count}`}
                                TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
                                tabmode={storyState.TabMode}
                                tdecktype={`${ColType}`}
                                // activeTab={this.state.TMColumnActiveTab}
                                minifyIcon={TmTabMinify}
                                viewState={'expandeddesktop'}
                              />
                            ))
                          ) : ('')
                        }
                        {FeedIndexer.length < this.state.feedlisttotalcount && (
                          <tr>
                            <td colSpan={10} className={` bordercellright ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                              <Row className={`marg-0 `} key={`tm_showmore`} >
                                <Col className=" align-self-center text-center" style={{ padding: '10px 10px 15px 10px' }}>
                                  <span>
                                    <button
                                      className="btn tryforfree-btn" style={{ padding: 5, paddingLeft: 10, paddingRight: 10 }}
                                      onClick={() => {
                                        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                                        window.Mixpanel.MixpanelProps.Column = 'Top coins';
                                        window.Mixpanel.actions.identify(this.props.userData.username);
                                        window.Mixpanel.actions.track(`Show more clicked in top coins`, window.Mixpanel.MixpanelProps).then((data) => {
                                          window.Mixpanel.actions.people.set({
                                            UserName: this.props.userData.username,
                                          });
                                          window.Mixpanel.actions.resetProp('Column');
                                        });
                                        this.EventListHandle.tm_showMore()
                                      }}
                                    >
                                      Show More
                                    </button>
                                  </span>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}
                        {
                          !FeedIndexer.length && (
                            <SingleTM
                              {...this.props}
                              index={StoryList.length}
                              key={`${ColType}null`}
                              tdecktype={`${ColType}`}
                              // activeTab={this.state.TMColumnActiveTab}
                              minifyIcon={TmTabMinify}
                              viewState={'expandeddesktop'}
                            />
                          )
                        }
                      </tbody>
                    </table>

                  </div>
                </div>
              ) : (
                <>

                  <TMTabs
                    listkey={this.props.listkey}
                    activeTab={this.state.TMColumnActiveTab}
                    changeTMTabs={this.props.SetTMActiveTab}
                    minify={TmTabMinify}
                    togglePopup={this.props.togglePopup}
                    toggleOverlay={this.props.toggleOverlay}
                    username={this.props.userData.username}
                    usertype={this.props.userData.usertype}
                    colType={ColType}
                    // updateCoinColumnPreferences={this.props.updateCoinColumnPreferences}
                    // cc_Preferences={this.props.cc_Preferences}
                    isPro={this.props.cc_Preferences.isPro === 'true'}
                    cc_index={this.props.cc_index}
                    expanded={this.props.cc_Preferences.expanded === 'true'}
                    viewState={'nonexpanded'}
                  />
                  <TMSubtitles
                    togglemaxCoinState={this.togglemaxCoinState}
                    maxcoinmenu={maxcoinmenu}
                    CoinMenuText={CoinMenuText}
                    cc_index={this.props.cc_index}
                    viewState={'nonexpanded'}
                    usertype={this.props.userData.usertype}
                  />
                  <TMTabTitles
                    {...this.props}
                    // activeTab={this.state.TMColumnActiveTab}
                    // changeTMTabs={this.props.SetTMActiveTab}
                    colType={ColType}
                    minify={TmTabMinify}
                    togglePopup={this.props.togglePopup}
                    toggleOverlay={this.props.toggleOverlay}
                    cc_index={this.props.cc_index}
                  />
                  <div
                    className={`cScroll tm bg-tmSubtitles-off ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                    style={{
                      minHeight: this.props.homeviewport.height - (165 - tmheightcorrection),
                      height: this.props.homeviewport.height - (165 - tmheightcorrection),
                      paddingLeft: '10',
                    }}
                    onScroll={this.handleScroll}
                    id="tmCScroll"
                  >
                    {
                      FeedIndexer.length ? (
                        FeedIndexer.map((stryIndex, count) => (
                          <SingleTM
                            {...this.props}
                            index={StoryList.length}
                            count={count + 1}
                            id={stryIndex.FeedId}
                            key={`${stryIndex.FeedId}${count}`}
                            TDeckColWidth={parseInt(this.props.colRect['width'].replace('px', ''))}
                            tabmode={storyState.TabMode}
                            tdecktype={`${ColType}`}
                            // activeTab={this.state.TMColumnActiveTab}
                            minifyIcon={TmTabMinify}
                            viewState={'nonexpanded'}
                          />
                        ))
                      ) : ('')
                    }
                    {FeedIndexer.length < this.state.feedlisttotalcount && (

                      <Row className={`marg-0 `} key={`tm_showmore`} >
                        <Col className=" align-self-center text-center" style={{ padding: '10px 10px 15px 10px' }}>
                          <span>
                            <button
                              className="btn tryforfree-btn" style={{ padding: 5, paddingLeft: 10, paddingRight: 10 }}
                              onClick={() => {
                                window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                                window.Mixpanel.MixpanelProps.Column = 'Top coins';
                                window.Mixpanel.actions.identify(this.props.userData.username);
                                window.Mixpanel.actions.track(`Show more clicked in top coins`, window.Mixpanel.MixpanelProps).then((data) => {
                                  window.Mixpanel.actions.people.set({
                                    UserName: this.props.userData.username,
                                  });
                                  window.Mixpanel.actions.resetProp('Column');
                                });
                                this.EventListHandle.tm_showMore()
                              }}
                            >
                              Show More
                            </button>
                          </span>
                        </Col>
                      </Row>

                    )}
                    {
                      !FeedIndexer.length && (
                        <SingleTM
                          {...this.props}
                          index={StoryList.length}
                          key={`${ColType}null`}
                          tdecktype={`${ColType}`}
                          // activeTab={this.state.TMColumnActiveTab}
                          minifyIcon={TmTabMinify}
                          viewState={'nonexpanded'}
                        />
                      )
                    }
                    {/* {StoryList} */}
                  </div>
                </>
              ) : ('')}



        {(ColType === 'search' || ColType === 'savedsearch') && (
          <SearchedTickerDetails
            ColType={ColType}
            coinEventListcollapse={this.state.CoinEventListcollapse}
            coinEventListcollapseHover={this.state.coinEventListcollapseHover}
            searchColumnCollapse={SearchListCollapse}
            toggleSearchColumnCollapse={this.props.collapseSearchList}
            toggleSavedSearchCollapse={() => {
              this.props.toggleCollapseSavedSearchList(
                searchSavedId,
                !JSON.parse(storyState.searchSaveData[searchSavedId].SavedDataDetails).columnCollapsePreference
              );
            }}
            {...this.props}
            colName={colName}
          />
        )}

        {ColType === 'tmd' && (
          <div
            className={`cScroll ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
            style={{ minHeight: this.props.homeviewport.height - 50, maxHeight: this.props.homeviewport.height, height: this.props.homeviewport.height - 50 }}
            onScroll={this.handleScroll}
            id="tmdCScroll"
          >
            {TmdViewArr}
          </div>
        )}
        {ColType === 'tmcd' && (
          <div
            className={`cScroll ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
            style={{ minHeight: this.props.homeviewport.height - 50, maxHeight: this.props.homeviewport.height, height: this.props.homeviewport.height - 50 }}
            onScroll={this.handleScroll}
            id="tmcdCScroll"
          >
            {tmcdArr}
          </div>
        )}
        {ColType !== 'tmd' && ColType !== 'tm' && ColType !== 'tmcd' ? (
          // <>
          <TweetDeckColumnBody
            {...this.props}
            ColType={ColType}
            handleScroll={this.handleScroll}
            coinDetailsCollapse={coinDetailsCollapse}
            TickerData={TickerData}
            isPro={this.props.cc_Preferences.isPro === 'true'}
          >

            {StoryList}
          </TweetDeckColumnBody>
        ) : ('')}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(ownProps.SecurityName, ownProps.SourceFilter)
  // console.log(state.trendingMentions.tmDetailPreferences)
  let cc_prefs = {}
  // let coindetailprefs = {}
  let coindetailprefData = {}
  if (ownProps.listkey.startsWith('Trending Mentions_')) {
    cc_prefs = state.trendingMentions.coin_column_preferences[ownProps.cc_index]
  }
  if (ownProps.listkey.startsWith('Coin_Column_details_')) {
    // console.log(state.trendingMentions.tmDetailPreferencesDataStore, ownProps.coinColumnIndex)
    // coindetailprefs = state.trendingMentions.tmDetailPreferences[ownProps.coinColumnIndex]
    coindetailprefData = ownProps.SecurityName !== '' && typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.SourceFilter}`] !== 'undefined' ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.SourceFilter}`] : {}
  }
  // console.log(ownProps.SecurityName, ownProps.SourceFilter, state.trendingMentions.tmDetailPreferencesDataStore, coindetailprefData)
  return {

    cc_Preferences: cc_prefs,
    // coindetailprefs,
    coindetailprefData,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    render: state.settings.render,
    iframelogo: state.settings.iframelogo,
    iframeasset: state.settings.iframeasset,
    iframeassetdata: state.settings.iframeassetdata,
    iframenav: state.settings.iframenav,
    iframefilters: state.settings.iframefilters,
    lang: state.settings.lang,
    // SecuritiesTickerIdList: state.filters.SecuritiesTickerIdList
  };
};

const mapDispatchToProps = {
  EnableLoader,
  updateiframeasset,
  updateiframeassetsecurity,
  deleteSavedSearch_LLU
};

export default connect(mapStateToProps, mapDispatchToProps)(TweetDeckColumn);
// export default TweetDeckColumn;
