import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../modules/helper.module';
import { connect } from 'react-redux';

import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../../static_data/translations';


class EventImportanceMenu extends Component {
    constructor(props) {
        super()
        this.state = {
            show_medium: props.show_medium,
            show_high: props.show_high,
            show_higher: props.show_higher,
            iframecolorfiltermenustate: false,
            iframecolorfiltermenu: ''

        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            show_medium: nextProps.show_medium,
            show_high: nextProps.show_high,
            show_higher: nextProps.show_higher
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.show_medium !== prevState.show_medium || this.state.show_high !== prevState.show_high || this.state.show_higher !== prevState.show_higher) {
            this.toggleIFrameColorFilterMenuState(true)
        }
    }
    toggleIFrameColorFilterMenuState = (forceshow = false) => {
        let ContainerRect = this.iframecolorfilter.getBoundingClientRect();
        let Pos = {
            right: this.props.lang && this.props.lang === 'ara' ? 'unset' : 20,
            left: this.props.lang && this.props.lang === 'ara' ? 20 : 'unset',
            // left: ContainerRect.left -30,
            // top: ContainerRect.bottom,
            top: 40,
        };
        const { storyState } = this.props;
        let content = (
            <div>
                <Row className={`marg-0 nopad`}>
                    <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tiphead-coinmenu brdr-bot-gray `}>
                        {/* <img className="tradeWindowClose-closeicon" style={{width: '15px'}} src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" /> */}
                        <Row className={`marg-0 nopad`}>
                            <Col className='bold' style={{ fontSize: '13px', textAlign: 'left', padding: '0px', color: this.props.Theme === 'light' ? "#252525" : "#ffffff" }}>
                                <span>


                                </span>
                            </Col>
                            <Col xs={1} sm={1} md={1} xl={1} lg={1} className="" style={{ marginTop: '-4px', cursor: 'pointer' }} >
                                <i className="fa fa-times ttmenuclose" onClick={this.toggleIFrameColorFilterMenuState}></i>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row className={`marg-0`} style={{ backgroundColor: this.props.Theme === 'light' ? '#ffffff' : '#0f1725', padding: "0px 10px 0px 10px" }}>
                    <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray`} onClick={() => { }}>
                        {/* ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'selected' : ''} */}
                        <div className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                            <span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                            </span>
                            {/* <span> Critical Events</span> */}
                            <span>
                                {
                                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Critical Events']
                                        : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Critical Events']
                                            : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Critical Events']
                                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Critical Events']
                                                    : 'Critical Events'
                                }
                            </span>
                            <span style={{ float: this.props.lang && this.props.lang === 'ara' ? 'left' : 'right' }}>
                                {/* <input
                                    type="checkbox"
                                    name='redcheckbox'
                                    checked={`${this.state.show_higher ? 'checked' : ''}`}
                                    // disabled={true}
                                    onChange={(e) => {
                                        this.Changeiframecolorfilter(3)
                                    }}
                                /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => this.Changeiframecolorfilter(3)}>
                                    <g id="icon-tick" transform="translate(16066 -11481)">
                                        <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={this.state.show_higher ? '#1da1f0' : '#ffffff'} stroke={this.state.show_higher ? '#1da1f0' : '#bbbbbb'} strokeWidth="1.5">
                                            <rect width="22" height="22" rx="0" stroke="none" />
                                            <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
                                        </g>
                                        {
                                            this.state.show_higher && (
                                                <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
                                            )
                                        }

                                    </g>
                                </svg>
                            </span>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray `} onClick={() => { }}>
                        {/* ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'selected' : ''} */}
                        <div className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                            <span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
                            </span>
                            {/* <span> Important Events</span> */}
                            <span>
                                {
                                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Important Events']
                                        : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Important Events']
                                            : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Important Events']
                                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Important Events']
                                                    : 'Important Events'
                                }
                            </span>
                            <span style={{ float: this.props.lang && this.props.lang === 'ara' ? 'left' : 'right' }}>
                                {/* <input
                                    type="checkbox"
                                    name='orangecheckbox'
                                    checked={`${this.state.show_high ? 'checked' : ''}`}
                                    onChange={(e) => {
                                        this.Changeiframecolorfilter(2)
                                    }}
                                /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => this.Changeiframecolorfilter(2)}>
                                    <g id="icon-tick" transform="translate(16066 -11481)">
                                        <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={this.state.show_high ? '#1da1f0' : '#ffffff'} stroke={this.state.show_high ? '#1da1f0' : '#bbbbbb'} strokeWidth="1.5">
                                            <rect width="22" height="22" rx="0" stroke="none" />
                                            <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
                                        </g>
                                        {
                                            this.state.show_high && (
                                                <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
                                            )
                                        }

                                    </g>
                                </svg>
                            </span>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem `} onClick={() => { }}>
                        {/* ${maxCoinListState === 10 && maxCoinListOutOfState === 1000 && FromWatchlist ? 'selected' : ''} */}
                        <div className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                            <span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
                                <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
                            </span>
                            {/* <span> Regular Events</span> */}
                            <span>
                                {
                                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Regular Events']
                                        : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Regular Events']
                                            : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Regular Events']
                                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Regular Events']
                                                    : 'Regular Events'
                                }
                            </span>
                            <span style={{ float: this.props.lang && this.props.lang === 'ara' ? 'left' : 'right', paddingBottom: '5px' }}>
                                {/* <input
                                    type="checkbox"
                                    name='yellowcheckbox'
                                    checked={`${this.state.show_medium ? 'checked' : ''}`}
                                    // defaultChecked={this.props.storyState.Show_MEDIUM}
                                    onChange={(e) => {
                                        this.Changeiframecolorfilter(1)

                                    }}
                                /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => this.Changeiframecolorfilter(1)}>
                                    <g id="icon-tick" transform="translate(16066 -11481)">
                                        <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={this.state.show_medium ? '#1da1f0' : '#ffffff'} stroke={this.state.show_medium ? '#1da1f0' : '#bbbbbb'} strokeWidth="1.5">
                                            <rect width="22" height="22" rx="0" stroke="none" />
                                            <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
                                        </g>
                                        {
                                            this.state.show_medium && (
                                                <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
                                            )
                                        }

                                    </g>
                                </svg>
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        )
        const containerStyleClass = `crl-tooltipMenu coincount normal-left ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
        // console.log(this.state.iframecolorfiltermenustate)
        // console.log(forceshow)
        // this.setState({
        //   ...this.state,
        //   iframecolorfiltermenustate: !this.state.iframecolorfiltermenustate
        // })

        this.setState({
            ...this.state,
            iframecolorfiltermenustate: forceshow === true ? true : !this.state.iframecolorfiltermenustate,
        }, () => {
            // console.log(this.state.iframecolorfiltermenustate)
            if (this.state.iframecolorfiltermenustate) {
                this.iframecolorfilterPopup(this.state.iframecolorfiltermenustate, content, Pos, containerStyleClass);
            } else {
                this.iframecolorfilterPopup(this.state.iframecolorfiltermenustate);
            }
        });
    }
    iframecolorfilterPopup = (showState, view, pos, containerClass) => {
        let popup = '';
        if (showState) {
            popup = (
                <div className={`marg-0 ${containerClass} ${!showState ? 'nodisp' : ''}`} style={{ ...pos, zIndex: 1203 }}>
                    {view}
                </div>
            );
        }
        // console.log(popup)
        this.setState({
            ...this.state,
            iframecolorfiltermenu: popup,
        });
    }
    Changeiframecolorfilter = (magnitude) => {
        // console.log(e.currentTarget.value, magnitude)
        let value;
        switch (magnitude) {
            case 1:
                value = this.state.show_medium;
                break;
            case 2:
                value = this.state.show_high;
                break;
            case 3:
                value = this.state.show_higher;
                break;
            default:
                break;
        }

        this.props.updateUserPreferences_EventImportance_Iframe(magnitude, !value)
        // this.setState({
        //   ...this.state,
        //   iframe_showmedium: magnitude === 1 ? !this.state.iframe_showmedium : this.state.iframe_showmedium,
        //   iframe_showhigh: magnitude === 2 ? !this.state.iframe_showhigh : this.state.iframe_showhigh,
        //   iframe_showhigher: magnitude === 3 ? !this.state.iframe_showhigher : this.state.iframe_showhigher,
        // }, () => {

        // })

    }
    render() {
        const { show_medium, show_high, show_higher, iframecolorfiltermenu } = this.state
        // console.log(show_medium, show_high, show_higher)
        //1 -> Yellow(Regular events) and above 2-> Orange(Important events) and above 3-> red(Critical events) only
        let ImportanceIndicator;
        if (show_medium === true) {
            ImportanceIndicator = 1
        } else if (show_medium === false && show_high === true) {
            ImportanceIndicator = 2
        } else if (show_medium === false && show_high === false && show_higher === true) {
            ImportanceIndicator = 3
        }
        return (
            <Col xs={4} sm={4} md={4} xl={4} lg={4} className={`${this.props.lang && this.props.lang === 'ara' ? 'text-left' : 'text-right'} ${!this.props.iframenav ? 'offset-6' : ''} nopad TDeck-window-head-content-title align-self-center ${this.props.Theme === 'light' ? 'lightmode ' : ''} ${this.props.iframenav || this.props.iframefilters ? 'iframenav' : ''} ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''}`} >
                <div style={{ marginRight: this.props.lang && this.props.lang === 'ara' ? '0px' : '20px', marginLeft: this.props.lang && this.props.lang === 'ara' ? '20px' : '0px' }}>
                    <span
                        style={{ border: '1px solid #1DA1F0', padding: '5px', borderRadius: '5px' }}
                        ref={(el) => { this.iframecolorfilter = el; }}
                    >
                        {/* <span>{
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Credibility']
                                : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Credibility']
                                    : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Credibility']
                                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Credibility']
                                            : 'Credibility'
                        } : </span> */}

                        <span style={{ padding: '0px 5px' }}>
                            <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                            <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${ImportanceIndicator >= 2 ? 'active' : ''} `}></span>
                            <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${ImportanceIndicator === 3 ? 'active' : ''} `}></span>
                        </span>

                        <span
                            onClick={(e) => {
                                // console.log('expand clicked')
                                this.toggleIFrameColorFilterMenuState()
                            }}
                        >
                            <img
                                style={{ width: '15px' }}
                                src={`${APPPATH.ImageUrl}/storyexpandicon.svg`}
                                className={`storyExpandIcon `}
                                alt=""
                            />
                            {/* ${this.props.collapse ? '' : 'transform180anticlock'} */}
                        </span>
                        {iframecolorfiltermenu}
                    </span>
                </div>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // console.log(state.trendingMentions.tmDetailPreferencesDataStore)

    return {
        lang: state.settings.lang,
        iframenav: state.settings.iframenav,
        iframefilters: state.settings.iframefilters,
        Theme: state.app_preferences.preferences.user_interface.Theme,
        show_medium: state.app_preferences.preferences.event_importance_filters.Gray_Colored_Events.Show_Gray,
        show_high: state.app_preferences.preferences.event_importance_filters.Yellow_Colored_Events.Show_Yellow,
        show_higher: state.app_preferences.preferences.event_importance_filters.Red_Colored_Events.Show_Red,
    };
};

const mapDispatchToProps = {
    // setcolumnlist
};

export default connect(mapStateToProps, mapDispatchToProps)(EventImportanceMenu);
