import { extrasecurities } from './static_data/extrasecurities';

const appGlobalState = {
  settings: {
    apploading: true,
    userData: {
      username: '',
      loginTime: '',
      expiryTime: '',
      expiresIn: '',
      token_type: '',
      token: '',
      isLoggedIn: false,
      TNC: false,
      InitialPasswordReset: false,
      userRole: '',
      usertype: 'None',
      userPackage: '',
      isPro: true,
      passlessLogin: false,
      loginlessuser: false
    },
    render: 'app',//other values:- iframe
    forcetheme: false,
    subscriptionStatus: 'free',
    VerifiedEmail: false,
    showPremiumBanner: true,
    checkListStatus: {
      SecuritiesCheckList: true,
      EventTypesCheckList: true,
    },
    welcomemode: false,
    maintenancemodeon: false,
    showtopreach: true,
    env: process.env.REACT_APP_ENV,
    api: {
      apiBaseUrl: process.env.REACT_APP_APIBASEURL,
      apiRequestHeaders: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
    systemTime: {},
    mixpanel: {
      token: process.env.REACT_APP_MIXPANELTOKEN,
    },
    googleMapKey: process.env.REACT_APP_GOOGLEMAP_KEY,
    notifications: {
      has: false,
      title: 'Crowdsense Notifications',
      body: '',
    },
    initDataRequest: true,
    messageNotifications: [],
    userFeedbacks: [],
    OrgUsers: [],
    toolTipGlobalRemover: false,
    toolTipGlobals: {
      resourceToolTipHandler: false,
      timelineToolTipHandler: false,
      likedislikeToolTipHandler: false,
      likeToolTipHandler: false,
      dislikeToolTipHandler: false,
      userinfoToolTipHandler: false,
      muteToolTipHandler: false,
      messageToolTipHandler: false,
      min_headlineTextTipHandler: false,
    },
    feedbackStatus: false,
    changePassword: {
      tried: false,
      customMessage: false,
      successFlag: false,
      status: '',
    },
    TimeZoneOffset: '',
    TimeZoneList: [],
    TickerIconsList: [],
    isMobileApp: false,
    installedAppData: [],
    // isMobileAppInstalled: false,
    AdSlotIds: {
      B: ['6357226805', '4456153473', '5440672901', '8624281582', '5940926853', '4436273497'],
      A: ['8711765709', '7323480656', '9566500617', '5949870990', '1113295529', '9921723838']
    },
    InFeedAdSlotIds: {
      A: ['6755035832', '5415454881', '9320972504', '1172957542', '8599203189'],
      B: ['9096399770', '2471281674', '2634933201', '4822112849', '4278369128']
    },
    freeTrial: false,
    freeTrialDetails: {
      trialEndDate: '',
      daystogo: 0
    },
    BeforeActiveColumn: '',
    ActiveColumn: '',
    AfterActiveColumn: '',
    columnlist: [],
    columnorderlist: [],
    columnorderlist_initload: false,
    verifiednewcolumnordersettings: false,
    existingColumnName: '',
    pinnedmainfeeds: 0,
    closedpinnedcolumn: 0,
    postponeupdate: false,
    initialcolumnloadcounter: 0,
    lang: false,
    iframelogo: false,
    iframeasset: false,
    iframeassetdata: false,
    iframenav: false,
    iframefilters: false,
  },
  filters: {
    eventTypes: [],
    Locations: [],
    eventFilter: [],
    locationFilter: [],
    securitiesRawCollection: [],
    securityFilter: [],
    securities: extrasecurities,
    securitiesFilter: [],
    securitiesResults: [],
    mentionTypes: [],
    mentiontypesFilter: [],
    locationContinents: [],
    continentFilteredLocations: {},
    SecuritiesTickerIdList: {},
    securityFilter_Req: false,
    FeedSources: [
      'Local News English', 'International News English', 'Financial News', 'Financial News Aggregator', 'Bloomberg', 'Reuters', 'Reddit', 'Telegram', 'Local News Local Language', 'WebSites', 'Twitter', 'Twitter Financial News', 'Telegram Financial News'
    ]
  },
  app_preferences: {
    preferences: {
      event_importance_filters: {
        Red_Colored_Events: {
          Show_Red: true,
          Desktop_Notifications_Red: true,
          Desktop_Notify_Breaking_Only_Red: false,
          Speech_Notifications_Red: false,
          SMS_Notifications_Red: true,
          Email_Notifications_Red: true,
          Email_Notify_Breaking_Only_Red: false,
        },
        Yellow_Colored_Events: {
          Show_Yellow: true,
          Desktop_Notifications_Yellow: true,
          Desktop_Notify_Breaking_Only_Yellow: false,
          Speech_Notifications_Yellow: false,
          SMS_Notifications_Yellow: true,
          Email_Notifications_Yellow: true,
          Email_Notify_Breaking_Only_Yellow: false,
        },
        Gray_Colored_Events: {
          Show_Gray: true,
          Desktop_Notifications_Gray: true,
          Desktop_Notify_Breaking_Only_Gray: false,
          Speech_Notifications_Gray: false,
          SMS_Notifications_Gray: false,
          Email_Notifications_Gray: true,
          Email_Notify_Breaking_Only_Gray: false,
        },
        White_Colored_Events: {
          Show_White: true
        },
        Non_Active_Curated_Events: {
          Show_NACE: '',
          Desktop_Notifications_NACE: false,
        },
      },
      email_preferences: {
        Daily_Notifications: true,
        Weekly_Notifications: true,
        None: false,
        Lite: true,
        Standard: false,
        Professional: false,
      },
      search_preferences: {
        Search_with_black: false,
        Search_with_gray: false,
        Search_with_white: false,
        Search_with_yellow: true,
        Search_with_orange: true,
        Search_with_red: true,
      },
      coindetailsearch_preferences: {
        CDSearch_with_black: false,
        CDSearch_with_gray: false,
        CDSearch_with_yellow: true,
        CDSearch_with_orange: true,
        CDSearch_with_red: true,
      },
      event_filters: {
        Locations_Related_Countries: false,
        Securities_Line: false,
        //   Mainstream_News: true,
        //   Local_News: true,
        //   Recurring_Events: true,
        //   Planned_Scheduled_Events: true
      },
      user_interface: {
        Tab_Mode: false,
        Mainstream_News: true,
        Early_Events: true,
        Analyzed_Events: true,
        Early_In_Analyzed_Events: true,
        Event_Collapsed_View: true,
        CD_Collapsed_View: true,
        Search_Collapsed_View: true,
        // SavedSearch_Collapsed_View: false,
        Trending_Mentions: true,
        // Local_News: true,
        RecentUpdateView: true,
        HasColumnOrderSettings: false,
        MenuFilter: 'Summary',
        Theme: 'dark'
      },
      localisation_preferences: {
        Email: '',
        Timezone: '',
        Phone: '',
      },
    },
    webNotifications: true,
    Install_Desktop_App: false,
    UserPreferenceUpdated: 0,
    UserPreferenceUpdatedCategory: '',
    preferenceRequestStatus: {
      User_Preference: true,
      Notification_Preference: true,
      Localisation_Preference: true,
      Phone_Preference: true,
      DesktopApp_Preference: true,
    },
  },
  feeds: {
    tweetListMaxCount: 200,
    preventStoryUpdate: false,
    feedReqData: {
      InitialFeedRequest: '',
      LastFeedRequest: '',
      LastFeedRequestEnd: '',
      InitialFeedResponse: '',
      LastFeedResponse: '',
      RequestState: false,
    },
    FeedsNotifications: {
      has: false,
      title: 'Crowdsense Notifications',
      body: '',
    },
    Stories: {},
    StoriesIndex: [],
    StoriesLatestDate: false,
    BreakingStories: {},
    BreakingStoriesIndex: [],
    AnalysedStories: {},
    AnalysedStoriesIndex: [],

    SearchForEventsFromSecFilters: false,
    feedDetails: {},
    feedOpenCloseList: {},
    feedResetHandle: false,
    feedDetailsRequests: 0
  },
  trendingMentions: {
    pinnedcoincolumn: 0,
    pinnedcoindetailcolumn: 0,
    coinsDump: [],
    tmFromWatchlist: true,
    tmListMaxCoinCount: 10,
    tmtotalmaxcoincount: 1000,
    tweetListMaxCount: 200,
    tmReqData: {
      InitialFeedRequest: '',
      LastFeedRequest: '',
      LastFeedRequestEnd: '',
      InitialFeedResponse: '',
      LastFeedResponse: '',
      RequestState: false,
    },
    tmFrame: {
      '1h': false,
      '1d': true,
      '1w': false,
    },
    activeTab: 'TopSentiment',
    TabColumnChangeRequest: [false],
    // packageName: 'mentionschangepositive',
    packageName: 'mentionschangepositive',
    tmSorted: false,
    tmSortedTab: '',
    tmSortedColumn: 'Sentiment Change',
    tmSortingType: 1,
    tmSortedIndex: [],
    autoRefresh: true,
    tm_autocall: [false],
    tmList: {},
    tmIndex: [],
    tmDetailPreferences: [],
    tmDetailPreferencesDataStore: {},

    tmDetailPreferencesTemplate: {

      CDSearch_with_black: 'false',
      CDSearch_with_gray: 'false',
      CDSearch_with_white: 'false',
      CDSearch_with_yellow: 'true',
      CDSearch_with_orange: 'true',
      CDSearch_with_red: 'true',
      SecurityName: '',
      SecurityTicker: '',
      CD_Collapsed_View: 'false',
      metric: 'Sentiment',
      columntype: 'tmcd',
      columnFilter: '',
      sourceFilter: '',
    },
    tmDetails: {
      FromTopCoins: false,
      TickerViewDetails: false,
      TickerData: {},
      TickerEventSearchSaveData: {},
      ExchangesAndRates: [],
      ExchangesAndRatesLoading: false,
      TickerEventList: {},
      TickerEventIndex: [],
      TickerDetailsFeedReqData: {
        InitialFeedRequest: '',
        LastFeedRequest: '',
        LastFeedRequestEnd: '',
        InitialFeedResponse: '',
        LastFeedResponse: '',
        RequestState: false,
      },
      TickerSearchData: {},
      TickerResearch: false,
    },
    WatchList: {},
    WatchListIndex: [],
    eventDetails: {},
    coin_column_preference_template: {
      columnTitle: 'Top Trending (Daily)',
      isPro: 'true',
      tmFromWatchlist: 'true',
      tmListMaxCoinCount: '10',
      tmtotalmaxcoincount: '1000',
      activeTab: 'TopSentiment',
      packageName: 'mentionspositive',
      // packageName: 'mentionschangepositive',
      tmSorted: 'false',
      tmSortedColumn: 'Sentiment',
      // tmSortedColumn: 'Sentiment Change',
      tmSortingType: '1',
      expanded: 'false',
      listIndex: '1',
      columntype: 'tm'
    },
    coin_column_preferences: [],
    coin_column_data: [{
      coinsDump: [],
      tmList: {},
      tmIndex: [],
    }],
    cc_tmReqData: [{
      InitialFeedRequest: '',
      LastFeedRequest: '',
      LastFeedRequestEnd: '',
      InitialFeedResponse: '',
      LastFeedResponse: '',
      RequestState: false,
    }],
    topcoinsinitialpreference: false
  },
  search: {
    tweetListMaxCount: 200,
    searchData: {},
    searchSaveData: {},
    searchsavedata_initload: false,
    searchSaveInit: false,
    searchSaveTitle: '',
    pinnedSearchSaveInit: false,
    pinnedSavedSearchListKey: "",

    searchFeeds: [],
    searchStories: {},
    searchStoriesIndex: [],

    searchSavedFeeds: {},
    searchSavedStories: {},
    searchSavedStoriesIndex: {},
    searchSavedStoriesData: {},

    searchSaveFeedReqData: {},
    withBlackUpdated: {},
    withCuratedUpdated: {},
    searchSaveDataUpdated: {},

    reSearch: false,
    searchRequest: false,

    searchCount: 0,
    pinnedSearchCount: 0,
    singleCoinSearch: false,
  },
  charts: {
    ChartTickers: {},
    ChartFeeds: [],
  },
  error: {
    fallbackError: false,
    general: false,
    systemTime: false,
    rootFeeds: false,
    auth: false,
    passwordlesslogin: false,
    passwordlesslogin_usernotfound: false,
    appError: '',
    data: {},
  },
};

export default appGlobalState;
